import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { getAuthCredentials } from "../../../utils/auth";

const DiscountModal = ({ open, onClose }) => {
  const [discountType, setDiscountType] = useState("None");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const { token } = getAuthCredentials();

  const handleSubmit = async () => {
    if (!startDate || !endDate) {
      alert("Start date and end date are required.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/reports/discount-summary`,
        {
          startDate,
          endDate,
          discountType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Discount_Summary_Report_${startDate}_${endDate}.xls`;
        link.click();

        Swal.fire({
          icon: "success",
          title: "Report Generated",
          text: "Discount Summary Report generated successfully.",
        });
      }
    } catch (error) {
      console.error("Error generating discount summary report:", error);

      if (error.response && error.response.status === 404) {
        Swal.fire({
          icon: "error",
          title: "No Data Found",
          text: "No sales data found for the given date range.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to generate the Discount Summary Report. Please try again.",
        });
      }
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Generate Discount Summary Report</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Please select the discount type and the date range for the Discount
          Summary Report:
        </Typography>

        <TextField
          select
          label="Discount Type"
          fullWidth
          value={discountType}
          onChange={(e) => setDiscountType(e.target.value)}
          sx={{ marginBottom: 2 }}
        >
          <MenuItem value="PWD">PWD</MenuItem>
          <MenuItem value="Senior">Senior</MenuItem>
          <MenuItem value="Athlete">Athlete</MenuItem>
          <MenuItem value="All">All</MenuItem>
        </TextField>

        <TextField
          label="Start Date"
          type="date"
          fullWidth
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          sx={{ marginBottom: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          label="End Date"
          type="date"
          fullWidth
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          sx={{ marginBottom: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Generating..." : "Generate"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountModal;
