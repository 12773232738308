import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { saveImage, getImage } from "../../../demo/utilities/indexedDB";
import { getAuthCredentials } from "../../../utils/auth";
import AddMenuCategoryDialog from "./AddMenuCategoryDialog";

const EditMenuItemDialog = ({
  open,
  onClose,
  item,
  ingredients,
  menuCategories,
  setMenuCategories,
  menuItems,
  fetchMenuCategories,
  fetchMenuData,
}) => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [ingredientQuantities, setIngredientQuantities] = useState({});
  const [price, setPrice] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [addons, setAddons] = useState([]);
  const [processTime, setProcessTime] = useState(0);
  const { token } = getAuthCredentials();

  useEffect(() => {
    if (item) {
      setName(item.menuName);
      setCode(item.code || item.menuName);
      setCategory({
        categoryId: item.categoryId,
        categoryName:
          menuCategories.find((cat) => cat.categoryId === item.categoryId)
            ?.categoryName || "",
      });
      setProcessTime(item.processTime || 0);
      setSelectedIngredients(
        item.menuIngredients.map((ingredient) => ({
          inventoryId: ingredient.ingredientId,
          inventoryName: ingredient.ingredientName,
          price: ingredient.ingredientPrice,
          quantity: ingredient.amount,
        }))
      );

      setIngredientQuantities(
        item.menuIngredients.reduce((acc, ingredient) => {
          acc[ingredient.ingredientName] = ingredient.amount;
          return acc;
        }, {})
      );

      setPrice(item.basePrice || "");
      setDescription(item.description);
      setAddons(item.addOns || []);
      setTags(item.menuTags?.join(", ") || "");

      if (item.imageId) {
        (async () => {
          const imageBlob = await getImage(item.imageId);
          const imageUrl = imageBlob ? URL.createObjectURL(imageBlob) : null;
          setImage(imageUrl);
        })();
      }
    }
  }, [item]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxFileSize = 5 * 1024 * 1024;

    if (!file) return;

    if (!allowedTypes.includes(file.type)) {
      alert("Unsupported file type. Only JPEG, PNG, and GIF are allowed.");
      setImage(null);
      setImageFile(null);
      return;
    }

    if (file.size > maxFileSize) {
      alert("File size exceeds 5MB.");
      setImage(null);
      setImageFile(null);
      return;
    }

    const previewUrl = URL.createObjectURL(file);
    setImage(previewUrl);
    setImageFile(file);
  };

  const handleSave = async () => {
    const formData = new FormData();
    if (imageFile) {
      formData.append("menuImage", imageFile);
    }

    const jsonPayload = {
      menuName: name,
      code: code || name,
      categoryId: category.categoryId,
      categoryName: category.categoryName,
      basePrice: price,
      description,
      addOns: addons,
      menuTags: tags.split(",").map((tag) => tag.trim()),
      menuIngredients: selectedIngredients.map((ingredient) => ({
        ingredientId: ingredient.inventoryId,
        ingredientName: ingredient.inventoryName,
        ingredientPrice: ingredient.price,
        amount:
          ingredientQuantities[ingredient.inventoryName] || ingredient.quantity,
      })),
      processTime: parseInt(processTime, 10),
    };
    formData.append("data", JSON.stringify(jsonPayload));

    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/edit/${item._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchMenuData();
      onClose();
    } catch (error) {
      console.error("Error updating menu item:", error);
      alert("Failed to update the menu item. Please try again.");
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/menu/delete-category`,
        {
          headers: { Authorization: `Bearer ${token}` },
          data: { categoryId },
        }
      );

      fetchMenuCategories();
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleAddIngredient = (ingredient) => {
    if (
      !selectedIngredients.some((item) => item.inventoryId === ingredient._id)
    ) {
      setSelectedIngredients([
        ...selectedIngredients,
        { ...ingredient, inventoryId: ingredient._id },
      ]);
      setIngredientQuantities((prev) => ({
        ...prev,
        [ingredient.inventoryName]: 1,
      }));
    }
  };

  const handleRemoveIngredient = (ingredient) => {
    const updatedIngredients = selectedIngredients.filter((item) => {
      if (!item.inventoryId || !ingredient.inventoryId) {
        return item.inventoryName !== ingredient.inventoryName;
      }
      return item.inventoryId !== ingredient.inventoryId;
    });

    setSelectedIngredients(updatedIngredients);

    const updatedQuantities = { ...ingredientQuantities };
    delete updatedQuantities[ingredient.inventoryName];
    setIngredientQuantities(updatedQuantities);
  };

  const handleQuantityChange = (ingredientName, value) => {
    setIngredientQuantities({
      ...ingredientQuantities,
      [ingredientName]: parseInt(value) || 1,
    });
  };

  const calculateTotalIngredientPrice = () => {
    return selectedIngredients.reduce((total, ingredient) => {
      const quantity = ingredientQuantities[ingredient.inventoryName] || 1;
      const ingredientPrice = ingredient.price / ingredient.amountOrdered;
      return total + ingredientPrice * quantity;
    }, 0);
  };

  const isFormValid =
    name && category && selectedIngredients.length > 0 && price;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Menu Item</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Code (Optional)"
          fullWidth
          margin="normal"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          helperText="Leave empty to use the name as the code."
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            value={category?.categoryId || ""}
            onChange={(e) => {
              const selectedCategory = menuCategories.find(
                (cat) => cat.categoryId === e.target.value
              );
              setCategory(selectedCategory);
            }}
            displayEmpty
            renderValue={(selected) => {
              const selectedCategory = menuCategories.find(
                (cat) => cat.categoryId === selected
              );
              return selectedCategory
                ? selectedCategory.categoryName
                : "Select Category";
            }}
          >
            {menuCategories.map((cat) => (
              <MenuItem
                key={cat.categoryId}
                value={cat.categoryId}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {cat.categoryName}
                <Tooltip title={"Delete category"}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(cat.categoryId);
                    }}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </MenuItem>
            ))}
            <MenuItem onClick={() => setIsAddingCategory(true)}>
              Add New Category
            </MenuItem>
          </Select>
        </FormControl>

        {isAddingCategory && (
          <AddMenuCategoryDialog
            open={isAddingCategory}
            onClose={() => setIsAddingCategory(false)}
            onCategoryAdded={(newCategory) => {
              setMenuCategories((prevCategories) => [
                ...prevCategories,
                newCategory,
              ]);
              setCategory(newCategory);
              setIsAddingCategory(false);
            }}
          />
        )}

        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
            >
              Upload Image
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleImageUpload}
              />
            </Button>
          </Grid>
          {image && (
            <Grid item>
              <img
                src={image}
                alt="Preview"
                style={{ width: 100, height: 100, objectFit: "cover" }}
              />
            </Grid>
          )}
        </Grid>

        <TextField
          label="Preparation Time (in minutes)"
          fullWidth
          margin="normal"
          type="number"
          value={processTime}
          onChange={(e) => setProcessTime(e.target.value)}
          helperText="Enter the estimated preparation time for this menu item"
        />

        <TextField
          select
          label="Add New Ingredients"
          fullWidth
          margin="normal"
          value=""
          onChange={(e) => handleAddIngredient(JSON.parse(e.target.value))}
        >
          {ingredients.map((ingredient) => (
            <MenuItem key={ingredient._id} value={JSON.stringify(ingredient)}>
              {ingredient.inventoryName}
            </MenuItem>
          ))}
        </TextField>

        <List>
          {selectedIngredients.map((ingredient) => (
            <ListItem key={ingredient.inventoryId}>
              <ListItemText primary={ingredient.inventoryName} />
              <TextField
                label="Quantity"
                type="number"
                value={ingredientQuantities[ingredient.inventoryName] || 1}
                onChange={(e) =>
                  handleQuantityChange(ingredient.inventoryName, e.target.value)
                }
                size="small"
                sx={{ width: 80, marginRight: 1 }}
              />
              <IconButton
                edge="end"
                onClick={() => handleRemoveIngredient(ingredient)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>

        <TextField
          label="Base Price"
          fullWidth
          margin="normal"
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          helperText={`Total Price of ingredients: ${calculateTotalIngredientPrice()}`}
        />

        <TextField
          label="Description"
          fullWidth
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <TextField
          select
          label="Upselling Addons"
          fullWidth
          margin="normal"
          value=""
          onChange={(e) => {
            const selectedAddon = menuItems.find(
              (menuItem) => menuItem.menuName === e.target.value
            );
            if (selectedAddon && !addons.includes(selectedAddon._id)) {
              setAddons([...addons, selectedAddon._id]);
            }
          }}
        >
          {menuItems
            .filter((menuItem) => menuItem.menuName !== name)
            .map((menuItem) => (
              <MenuItem key={menuItem._id} value={menuItem.menuName}>
                {menuItem.menuName}
              </MenuItem>
            ))}
        </TextField>

        <List>
          {addons.map((addonId) => {
            const addon = menuItems.find(
              (menuItem) => menuItem._id === addonId
            );
            return (
              <ListItem key={addonId}>
                <ListItemText primary={addon?.menuName || "Unknown Addon"} />
                <IconButton
                  edge="end"
                  onClick={() =>
                    setAddons(addons.filter((id) => id !== addonId))
                  }
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            );
          })}
        </List>

        <TextField
          label="Tags"
          fullWidth
          margin="normal"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          helperText="Separate tags with commas"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={!isFormValid}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMenuItemDialog;
