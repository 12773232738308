import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import SecureLS from "secure-ls";
import Swal from "sweetalert2";
import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

// Dialogs
import AddInventoryDialog from "./Dialogs/AddInventoryDialog";
import EditInventoryDialog from "./Dialogs/EditInventoryDialog";
import EditInventoryInstanceDialog from "./Dialogs/EditInventoryInstanceDialog";
import RestockInventoryDialog from "./Dialogs/RestockInventoryDialog";
import AlertDialog from "./Dialogs/AlertDialog";
import LegendDialog from "./Dialogs/LegendDialog";

// Functions
import HandleArchiveItem from "./Functions/HandleArchiveItem";
import HandleDeleteItem from "./Functions/HandleDeleteItem";
import handleAddInventory from "./Functions/HandleAddInventory";
import handleRestockInventory from "./Functions/handleRestockInventory";
import InventoryHero from "./InventoryHero";
import { getAuthCredentials } from "../../utils/auth";

const ls = new SecureLS({ encodingType: "aes" });

const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToArchive, setItemToArchive] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [restockDialogOpen, setRestockDialogOpen] = useState(false);
  const [legendDialogOpen, setLegendDialogOpen] = useState(false);
  const [itemToRestock, setItemToRestock] = useState(null);
  const defaultCurrencySymbol = "₱";
  const [currencySymbol, setCurrencySymbol] = useState(defaultCurrencySymbol);
  const [searchQuery, setSearchQuery] = useState("");
  const [editInstanceDialogOpen, setEditInstanceDialogOpen] = useState(false);
  const [instanceToEdit, setInstanceToEdit] = useState(null);
  const [instanceIndexToEdit, setInstanceIndexToEdit] = useState(null);
  const [inventoryIdForInstance, setInventoryIdForInstance] = useState(null);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [expiredItems, setExpiredItems] = useState([]);
  const [lowStockItems, setLowStockItems] = useState([]);

  const navigate = useNavigate();
  const hasShownAlert = useRef(false);

  const { user, token, posId } = getAuthCredentials(navigate);
  if (!user || !token || !posId) {
    return;
  }

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/categories`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCategories(response.data.categories || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchInventory = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/fetch`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const inventoryItems = response.data.inventoryItems || [];
      const currentDate = new Date();

      const expired = [];
      const lowStock = [];

      inventoryItems.forEach((item) => {
        const totalCurrentAmount = item.instances
          .filter((instance) => new Date(instance.expiryDate) >= currentDate)
          .reduce((sum, instance) => sum + (instance.currentAmount || 0), 0);

        if (totalCurrentAmount <= 20) {
          lowStock.push({
            inventoryName: item.inventoryName,
            currentAmount: totalCurrentAmount,
          });
        }

        item.instances.forEach((instance) => {
          if (new Date(instance.expiryDate) < currentDate) {
            expired.push({
              inventoryName: item.inventoryName,
              expiryDate: instance.expiryDate,
            });
          }
        });
      });

      setExpiredItems(expired);
      setLowStockItems(lowStock);

      if (
        !hasShownAlert.current &&
        (expired.length > 0 || lowStock.length > 0)
      ) {
        setAlertDialogOpen(true);
        hasShownAlert.current = true;
      }

      setInventory(inventoryItems);
    } catch (error) {
      console.error("Error fetching inventory:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInventory();
      await fetchCategories();
    };
    fetchData();
  }, [navigate]);

  const openDeleteDialog = (item) => {
    setItemToDelete(item);
    setDeleteDialogOpen(true);
  };

  const openArchiveDialog = (item) => {
    setItemToArchive(item);
    setArchiveDialogOpen(true);
  };

  const openEditDialog = (item) => {
    setItemToEdit(item);
    setEditDialogOpen(true);
  };

  const getItemBackgroundColor = (expiryDate) => {
    if (!expiryDate) return "#ffffff";
    const today = dayjs().startOf("day");
    const expiry = dayjs(expiryDate).startOf("day");
    const diffDays = expiry.diff(today, "day");

    if (diffDays >= 4) return "#90EE90";
    if (diffDays >= 0 && diffDays <= 3) return "#FFFF99";
    if (diffDays < 0) return "#FFCCCC";
    return "#ffffff";
  };

  const groupedInventory = inventory.reduce((acc, item) => {
    const category = item.categoryName || "Uncategorized";
    const instance = item.instances && item.instances[0];

    const expiryDate = instance
      ? dayjs(instance.expiryDate).startOf("day")
      : null;
    const today = dayjs().startOf("day");

    if (expiryDate && expiryDate.isBefore(today)) {
      acc["Expired"] = acc["Expired"] || [];
      acc["Expired"].push(item);
    } else {
      acc[category] = acc[category] || [];
      acc[category].push(item);
    }

    return acc;
  }, {});

  const sortedCategories = Object.keys(groupedInventory).sort((a, b) => {
    if (a === "Expired") return -1;
    if (b === "Expired") return 1;
    return 0;
  });

  const filteredInventory = sortedCategories.filter((category) => {
    return (
      category.toLowerCase().includes(searchQuery.toLowerCase()) ||
      groupedInventory[category].some((item) =>
        item.inventoryName.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  });

  return (
    <Box sx={{ padding: 3 }}>
      <InventoryHero />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 4,
          gap: 2,
        }}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenDialog(true)}
        >
          Add New Inventory
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setLegendDialogOpen(true)}
        >
          Legend
        </Button>
      </Box>

      {filteredInventory.length > 0 ? (
        filteredInventory.map((category, index) => (
          <Box key={index} sx={{ marginBottom: 4 }}>
            <Typography variant="h5" gutterBottom>
              {category}
            </Typography>
            <Grid container spacing={2}>
              {groupedInventory[category].map((item, idx) => {
                const totalPurchaseAmount = item.instances
                  .filter((instance) =>
                    dayjs(instance.expiryDate).isAfter(dayjs().add(1, "day"))
                  )
                  .reduce(
                    (sum, instance) => sum + (instance.purchaseAmount || 0),
                    0
                  );

                const totalCurrentAmount = item.instances
                  .filter((instance) =>
                    dayjs(instance.expiryDate).isAfter(dayjs().add(1, "day"))
                  )
                  .reduce(
                    (sum, instance) => sum + (instance.currentAmount || 0),
                    0
                  );

                const totalInventoryPrice = item.instances
                  .filter((instance) =>
                    dayjs(instance.expiryDate).isAfter(dayjs().add(1, "day"))
                  )
                  .reduce(
                    (sum, instance) => sum + (instance.inventoryPrice || 0),
                    0
                  );

                return (
                  <Grid item xs={12} key={idx}>
                    <Box
                      sx={{
                        padding: 2,
                        border: "1px solid #ccc",
                        borderRadius: 2,
                        backgroundColor:
                          item.instances.length === 0
                            ? "#ffffff"
                            : item.instances.some((instance) =>
                                dayjs(instance.expiryDate).isBefore(dayjs())
                              )
                            ? "#FFCCCC"
                            : item.instances.some((instance) =>
                                dayjs(instance.expiryDate).isBefore(
                                  dayjs().add(4, "day")
                                )
                              )
                            ? "#FFFF99"
                            : "#90EE90",
                        transition: "transform 0.2s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.02)",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                        },
                        cursor: "pointer",
                        position: "relative",
                      }}
                      onClick={() => {
                        const updatedInventory = inventory.map((inv) =>
                          inv._id === item._id
                            ? { ...inv, isOpen: !inv.isOpen }
                            : inv
                        );
                        setInventory(updatedInventory);
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h6">
                          {item.inventoryName}
                        </Typography>
                        {item.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Box>
                      <Typography>
                        Total Purchased Items: {totalPurchaseAmount || "N/A"}
                      </Typography>
                      <Typography>
                        Current Stock of Items: {totalCurrentAmount || "N/A"}
                      </Typography>
                      <Typography>
                        Total Purchase Price: {currencySymbol}
                        {totalInventoryPrice.toFixed(2) || "N/A"}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: 1,
                          marginTop: 2,
                          position: "absolute",
                          bottom: 8,
                          right: 8,
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setItemToEdit(item);
                            setEditDialogOpen(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setItemToRestock(item);
                            setRestockDialogOpen(true);
                          }}
                        >
                          Restock
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setItemToArchive({
                              inventoryId: item._id,
                              inventoryName: item.inventoryName,
                              isMainInventory: true,
                            });
                            setArchiveDialogOpen(true);
                          }}
                        >
                          Archive
                        </Button>
                      </Box>
                    </Box>

                    {item.isOpen && (
                      <Box sx={{ marginTop: 2 }}>
                        {item.instances.map((instance, instIdx) => (
                          <Box
                            key={instIdx}
                            sx={{
                              padding: 2,
                              border: "1px solid #ddd",
                              borderRadius: 2,
                              marginBottom: 2,
                              backgroundColor: getItemBackgroundColor(
                                instance.expiryDate
                              ),
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                display: "flex",
                                gap: 1,
                              }}
                            >
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openDeleteDialog({
                                    inventoryId: item._id,
                                    instanceIndex: instIdx,
                                    inventoryName: item.inventoryName,
                                  });
                                }}
                                color="error"
                                size="small"
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openArchiveDialog({
                                    inventoryId: item._id,
                                    instanceIndex: instIdx,
                                    inventoryName: item.inventoryName,
                                    isMainInventory: false,
                                  });
                                }}
                                color="secondary"
                                size="small"
                              >
                                <ArchiveIcon />
                              </IconButton>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setInstanceToEdit(instance);
                                  setInstanceIndexToEdit(instIdx);
                                  setInventoryIdForInstance(item._id);
                                  setEditInstanceDialogOpen(true);
                                }}
                                color="primary"
                                size="small"
                              >
                                <EditIcon />
                              </IconButton>
                            </Box>
                            <Typography>
                              Items Ordered: {instance.purchaseAmount || "N/A"}
                            </Typography>
                            <Typography>
                              Current Items: {instance.currentAmount || "N/A"}
                            </Typography>
                            <Typography>
                              Price: {currencySymbol}
                              {instance.inventoryPrice.toFixed(2) || "N/A"}
                            </Typography>
                            <Typography>
                              Delivery Date:{" "}
                              {instance.deliveryDate
                                ? new Date(
                                    instance.deliveryDate
                                  ).toLocaleDateString()
                                : "N/A"}
                            </Typography>
                            <Typography>
                              Expiry Date:{" "}
                              {instance.expiryDate
                                ? new Date(
                                    instance.expiryDate
                                  ).toLocaleDateString()
                                : "N/A"}
                            </Typography>
                            <Typography
                              sx={{
                                position: "absolute",
                                bottom: 8,
                                right: 8,
                                fontWeight: "bold",
                                color:
                                  instance.status === "Active"
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {instance.status === "Active"
                                ? "Active"
                                : "Inactive"}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        ))
      ) : (
        <Typography align="center">No inventory found</Typography>
      )}

      <AddInventoryDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onAdd={(newItem) =>
          handleAddInventory(
            newItem,
            token,
            posId,
            fetchInventory,
            (prevInventory) => [
              ...(prevInventory?.inventoryItems || []),
              newItem,
            ],
            setOpenDialog
          )
        }
        categories={categories}
        fetchCategories={fetchCategories}
        setCategories={setCategories}
        setInventory={setInventory}
      />

      {deleteDialogOpen && itemToDelete && (
        <HandleDeleteItem
          item={itemToDelete}
          onClose={() => setDeleteDialogOpen(false)}
          onDeleteConfirm={async () => {
            try {
              await fetchInventory();
              setDeleteDialogOpen(false);
            } catch (error) {
              console.error(
                "Error refetching inventory after deletion:",
                error
              );
            }
          }}
          token={token}
        />
      )}

      {archiveDialogOpen && itemToArchive && (
        <HandleArchiveItem
          item={itemToArchive}
          onClose={() => setArchiveDialogOpen(false)}
          onArchiveConfirm={async () => {
            try {
              await fetchInventory();
              setArchiveDialogOpen(false);
            } catch (error) {
              console.error(
                "Error refetching inventory after archiving:",
                error
              );
            }
          }}
        />
      )}

      <EditInventoryDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        item={itemToEdit}
        categories={categories}
        fetchCategories={fetchCategories}
        fetchInventory={fetchInventory}
        setCategories={setCategories}
      />
      <EditInventoryInstanceDialog
        open={editInstanceDialogOpen}
        onClose={() => setEditInstanceDialogOpen(false)}
        inventoryId={inventoryIdForInstance}
        instance={instanceToEdit}
        instanceIndex={instanceIndexToEdit}
        fetchInventory={fetchInventory}
      />

      {restockDialogOpen && itemToRestock && (
        <RestockInventoryDialog
          open={restockDialogOpen}
          onClose={() => setRestockDialogOpen(false)}
          onRestock={async (restockData) => {
            await handleRestockInventory(
              itemToRestock._id,
              restockData,
              token,
              fetchInventory
            );
            setRestockDialogOpen(false);
          }}
        />
      )}

      <AlertDialog
        open={alertDialogOpen}
        onClose={() => setAlertDialogOpen(false)}
        expiredItems={expiredItems}
        lowStockItems={lowStockItems}
      />
      <LegendDialog
        open={legendDialogOpen}
        onClose={() => setLegendDialogOpen(false)}
      />
    </Box>
  );
};

export default Inventory;
