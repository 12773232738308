import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Pagination,
} from "@mui/material";
import AddRoleModal from "./AddRoleModal";
import EditRoleModal from "./EditRoleModal";

const formatModuleName = (moduleName) => {
  return moduleName.replace(/([A-Z])/g, " $1").trim();
};

const RolesModal = ({
  open,
  onClose,
  roles,
  onEditRole,
  onDeleteRole,
  onRoleAdded,
}) => {
  const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState(false);
  const [isEditRoleModalOpen, setIsEditRoleModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const rolesPerPage = 10;

  useEffect(() => {
    const filtered = roles.filter((role) =>
      role.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredRoles(
      filtered.slice((page - 1) * rolesPerPage, page * rolesPerPage)
    );
  }, [roles, searchTerm, page]);

  const handleAddRoleClose = () => {
    setIsAddRoleModalOpen(false);
  };

  const handleEditRoleClick = (role) => {
    setSelectedRole(role);
    setIsEditRoleModalOpen(true);
  };

  const handleRoleUpdated = async (roleId, updatedData) => {
    if (!selectedRole || !selectedRole._id) {
      console.error("Selected role or role ID is missing.");
      return;
    }
    await onEditRole(roleId, updatedData);
    setIsEditRoleModalOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>Roles Management</DialogTitle>
        <DialogContent>
          <TextField
            label="Search Role Name"
            variant="outlined"
            size="small"
            fullWidth
            sx={{ marginBottom: 2, marginTop: 1 }}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(1);
            }}
          />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Role Name</TableCell>
                  <TableCell>Modules</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRoles.map((role) => (
                  <TableRow key={role._id}>
                    <TableCell>{role.name}</TableCell>
                    <TableCell>
                      {role.modules.map(formatModuleName).join(", ")}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ marginRight: 1 }}
                        onClick={() => handleEditRoleClick(role)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => onDeleteRole(role._id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(
              roles.filter((role) =>
                role.name.toLowerCase().includes(searchTerm.toLowerCase())
              ).length / rolesPerPage
            )}
            page={page}
            onChange={(e, value) => setPage(value)}
            color="primary"
            sx={{ marginTop: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setIsAddRoleModalOpen(true)}
          >
            Add Role
          </Button>
        </DialogActions>
      </Dialog>

      <AddRoleModal
        open={isAddRoleModalOpen}
        onClose={handleAddRoleClose}
        onRoleAdded={onRoleAdded}
      />
      <EditRoleModal
        open={isEditRoleModalOpen}
        onClose={() => setIsEditRoleModalOpen(false)}
        role={selectedRole}
        onRoleUpdated={handleRoleUpdated}
      />
    </>
  );
};

export default RolesModal;
