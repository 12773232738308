import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  TextField,
  Pagination,
} from "@mui/material";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import axios from "axios";
import InventoryHero from "./InventoryHero";
import { getAuthCredentials } from "../../utils/auth";

const InventoryArchive = () => {
  const [archivedInventory, setArchivedInventory] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("expiryDate");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const { token } = getAuthCredentials();

  useEffect(() => {
    const fetchArchivedInventory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/archive`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: { page, limit: 10, search: searchTerm },
          }
        );

        const { archivedInventory, totalPages } = response.data;
        setArchivedInventory(
          archivedInventory
            .filter((archiveItem) => archiveItem.inventoryArchive?.length > 0)
            .flatMap((archiveItem) =>
              archiveItem.inventoryArchive.map((item) => ({
                ...item,
                type: archiveItem.type || "Unknown",
                instances: item.instances || [],
                parentId: archiveItem._id,
              }))
            )
        );
        setTotalPages(totalPages);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setArchivedInventory([]);
          Swal.fire({
            icon: "info",
            title: "No Archived Items",
            text: error.response.data.message,
          });
        } else {
          console.error("Error fetching archived inventory:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to fetch archived inventory.",
          });
        }
      }
    };

    fetchArchivedInventory();
  }, [token, page, searchTerm]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchSubmit = () => {
    setSearchTerm(searchInput);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === "expiryDate") {
      const latestExpiryA = a.instances.reduce(
        (latest, instance) =>
          instance.expiryDate &&
          (!latest || dayjs(instance.expiryDate).isAfter(latest))
            ? instance.expiryDate
            : latest,
        null
      );
      const latestExpiryB = b.instances.reduce(
        (latest, instance) =>
          instance.expiryDate &&
          (!latest || dayjs(instance.expiryDate).isAfter(latest))
            ? instance.expiryDate
            : latest,
        null
      );

      if (!latestExpiryA && !latestExpiryB) return 0;
      if (!latestExpiryA) return -1;
      if (!latestExpiryB) return 1;

      return dayjs(latestExpiryA).isBefore(dayjs(latestExpiryB)) ? -1 : 1;
    }
    return 0;
  };

  const handleRestore = async (item, instanceId, type) => {
    console.log("item:", item);
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/unarchive`,
        {
          inventoryId: item.inventoryId,
          instanceId,
          type,
          parentId: item.parentId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: `Instance from ${item.inventoryName} unarchived successfully.`,
      });

      setArchivedInventory(
        (prevInventory) =>
          prevInventory
            .map((archivedItem) => {
              if (archivedItem.inventoryId === item.inventoryId) {
                const updatedInstances = archivedItem.instances.filter(
                  (instance) => instance._id !== instanceId
                );

                return { ...archivedItem, instances: updatedInstances };
              }
              return archivedItem;
            })
            .filter((archivedItem) => archivedItem.instances.length > 0) // Remove inventory without instances
      );
    } catch (error) {
      console.error("Error unarchiving instance:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Failed to unarchive the instance from ${item.inventoryName}. Please try again.`,
      });
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <InventoryHero />
      <Typography variant="h5" gutterBottom>
        Archived Inventory
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <TextField
          label="Search Inventory"
          variant="outlined"
          size="small"
          value={searchInput}
          onChange={handleSearchChange}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSearchSubmit}
        >
          Search
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sortDirection={orderBy === "inventoryName" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "inventoryName"}
                  direction={orderBy === "inventoryName" ? order : "asc"}
                  onClick={() => handleSort("inventoryName")}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Items Ordered</TableCell>
              <TableCell>Current Items</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Delivery Date</TableCell>
              <TableCell>Expiry Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {archivedInventory
              .flatMap((item) => {
                if (item.instances && item.instances.length > 0) {
                  const latestInstance = item.instances.reduce(
                    (latest, instance) => {
                      if (
                        !latest ||
                        new Date(instance.expiryDate) >
                          new Date(latest.expiryDate)
                      ) {
                        return instance;
                      }
                      return latest;
                    },
                    null
                  );

                  return {
                    ...latestInstance,
                    inventoryName: item.inventoryName,
                    inventoryId: item.inventoryId,
                    type: item.type || "Unknown",
                  };
                }
                return [];
              })
              .sort((a, b) => new Date(b.expiryDate) - new Date(a.expiryDate))
              .map((instance, index) => (
                <TableRow
                  key={`${instance.inventoryId}-${instance._id}-${index}`}
                  sx={{
                    transition: "background-color 0.3s",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <TableCell>{instance.inventoryName}</TableCell>
                  <TableCell>{instance.type || "Unknown"}</TableCell>
                  <TableCell>{instance.purchaseAmount || "N/A"}</TableCell>
                  <TableCell>{instance.currentAmount || "N/A"}</TableCell>
                  <TableCell>{instance.inventoryPrice || "N/A"}</TableCell>
                  <TableCell>
                    {instance.deliveryDate
                      ? new Date(instance.deliveryDate)
                          .toISOString()
                          .split("T")[0]
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {instance.expiryDate
                      ? new Date(instance.expiryDate)
                          .toISOString()
                          .split("T")[0]
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        handleRestore(
                          {
                            inventoryId: instance.inventoryId,
                            inventoryName: instance.inventoryName,
                          },
                          instance._id.toString(),
                          instance.type
                        )
                      }
                    >
                      Restore
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" mt={3}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default InventoryArchive;
