import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
} from "@mui/material";
import { Description, BarChart, ShoppingCart } from "@mui/icons-material";
import ReportHero from "./ReportHero";
import axios from "axios";
import OrderModal from "./Modal/OrderModal";
import SystemTrailModal from "./Modal/SystemTrailModal";
import BIRSalesModal from "./Modal/BIRSalesModal";
import DiscountModal from "./Modal/DiscountModal";
import AdjustmentModal from "./Modal/AdjustmentModal";
import EJournalModal from "./Modal/EJournalModal";
import { getAuthCredentials } from "../../utils/auth";

const Reports = () => {
  const [xReadingDialogOpen, setXReadingDialogOpen] = useState(false);
  const [zReadingDialogOpen, setZReadingDialogOpen] = useState(false);
  const [adjustmentDialogOpen, setAdjustmentDialogOpen] = useState(false);
  const [eJournalDialogOpen, setEJournalDialogOpen] = useState(false);
  const [systemTrailDialogOpen, setSystemTrailDialogOpen] = useState(false);
  const [birSalesDialogOpen, setBIRSalesDialogOpen] = useState(false);
  const [discountSummaryDialogOpen, setDiscountSummaryDialogOpen] =
    useState(false);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [xReadingDateFrom, setXReadingDateFrom] = useState("");
  const [xReadingDateTo, setXReadingDateTo] = useState("");
  const [zReadingDate, setZReadingDate] = useState("");
  const { token } = getAuthCredentials();

  const handleOrderGenerated = (orderData) => {
    console.log("Order generated:", orderData);
  };

  const shifts = [
    { value: "Morning", label: "Morning Shift" },
    { value: "Afternoon", label: "Afternoon Shift" },
    { value: "Evening", label: "Evening Shift" },
  ];

  const handleZReadingSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/generate-zreading`,
        {
          date: zReadingDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check response from backend
      if (response.status === 200) {
        alert("Z-Reading generated successfully");
      }
    } catch (error) {
      console.error("Error generating Z-Reading:", error);
      alert("Error generating Z-Reading");
    }

    setZReadingDialogOpen(false);
  };

  const handleXReadingSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/generate-xreading`,
        {
          startDate: xReadingDateFrom,
          endDate: xReadingDateTo,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        alert("X-Reading generated successfully");
      }
    } catch (error) {
      console.error("Error generating X-Reading:", error);
      alert("Error generating X-Reading");
    }

    setXReadingDialogOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <ReportHero />
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        {/* X-Reading */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ display: "flex", marginBottom: 3 }}
        >
          <Paper
            elevation={3}
            sx={{
              width: "100%",
              padding: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Description sx={{ fontSize: 60, marginBottom: 2 }} />
            <Typography variant="h6" gutterBottom>
              Generate X-Reading
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setXReadingDialogOpen(true)}
            >
              Generate
            </Button>
          </Paper>
        </Grid>

        {/* Z-Reading */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          sx={{ display: "flex", marginBottom: 3 }}
        >
          <Paper
            elevation={3}
            sx={{
              width: "100%",
              padding: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <BarChart sx={{ fontSize: 60, marginBottom: 2 }} />
            <Typography variant="h6" gutterBottom>
              Generate Z-Reading
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setZReadingDialogOpen(true)}
            >
              Generate
            </Button>
          </Paper>
        </Grid>
      </Grid>

      {/* Generate Sample Order */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ display: "flex", marginBottom: 3 }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <ShoppingCart sx={{ fontSize: 60, marginBottom: 2 }} />
          <Typography variant="h6" gutterBottom>
            Generate Sample Order
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOrderModalOpen(true)}
          >
            Generate
          </Button>
        </Paper>
      </Grid>

      {/* Generate System Log Report */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ display: "flex", marginBottom: 3 }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <BarChart sx={{ fontSize: 60, marginBottom: 2 }} />
          <Typography variant="h6" gutterBottom>
            Generate System Log Report
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSystemTrailDialogOpen(true)}
          >
            Generate
          </Button>
        </Paper>
      </Grid>

      {/* Generate BIR Sales Report */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ display: "flex", marginBottom: 3 }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <BarChart sx={{ fontSize: 60, marginBottom: 2 }} />
          <Typography variant="h6" gutterBottom>
            Generate BIR Sales Report
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setBIRSalesDialogOpen(true)}
          >
            Generate
          </Button>
        </Paper>
      </Grid>

      {/* Generate Discount Report */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ display: "flex", marginBottom: 3 }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <BarChart sx={{ fontSize: 60, marginBottom: 2 }} />
          <Typography variant="h6" gutterBottom>
            Generate Discount Summary Report
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setDiscountSummaryDialogOpen(true)}
          >
            Generate
          </Button>
        </Paper>
      </Grid>

      {/* Generate Adjustment Records */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ display: "flex", marginBottom: 3 }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <BarChart sx={{ fontSize: 60, marginBottom: 2 }} />
          <Typography variant="h6" gutterBottom>
            Generate Adjustment Records
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAdjustmentDialogOpen(true)}
          >
            Generate
          </Button>
        </Paper>
      </Grid>

      {/* Generate E-Journal */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ display: "flex", marginBottom: 3 }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <BarChart sx={{ fontSize: 60, marginBottom: 2 }} />
          <Typography variant="h6" gutterBottom>
            Generate E-Journal
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setEJournalDialogOpen(true)}
          >
            Generate
          </Button>
        </Paper>
      </Grid>

      {/* X-Reading Dialog */}
      <Dialog
        open={xReadingDialogOpen}
        onClose={() => setXReadingDialogOpen(false)}
      >
        <DialogTitle>Generate X-Reading</DialogTitle>
        <DialogContent>
          <TextField
            label="Select Start Date and Time"
            type="datetime-local"
            value={xReadingDateFrom}
            onChange={(e) => setXReadingDateFrom(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2, marginTop: 1 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Select End Date and Time"
            type="datetime-local"
            value={xReadingDateTo}
            onChange={(e) => setXReadingDateTo(e.target.value)}
            fullWidth
            sx={{ marginBottom: 2 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setXReadingDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleXReadingSubmit}
            variant="contained"
            color="primary"
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>

      {/* Z-Reading Dialog */}
      <Dialog
        open={zReadingDialogOpen}
        onClose={() => setZReadingDialogOpen(false)}
      >
        <DialogTitle>Generate Z-Reading</DialogTitle>
        <DialogContent>
          <TextField
            label="Select Date"
            type="date"
            value={zReadingDate}
            sx={{ marginTop: 1 }}
            onChange={(e) => setZReadingDate(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setZReadingDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleZReadingSubmit}
            variant="contained"
            color="primary"
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>

      <OrderModal
        open={orderModalOpen}
        onClose={() => setOrderModalOpen(false)}
        onOrderGenerated={handleOrderGenerated}
      />

      <SystemTrailModal
        open={systemTrailDialogOpen}
        onClose={() => setSystemTrailDialogOpen(false)}
      />

      <BIRSalesModal
        open={birSalesDialogOpen}
        onClose={() => setBIRSalesDialogOpen(false)}
      />

      <DiscountModal
        open={discountSummaryDialogOpen}
        onClose={() => setDiscountSummaryDialogOpen(false)}
      />

      <AdjustmentModal
        open={adjustmentDialogOpen}
        onClose={() => setAdjustmentDialogOpen(false)}
      />

      <EJournalModal
        open={eJournalDialogOpen}
        onClose={() => setEJournalDialogOpen(false)}
      />
    </Box>
  );
};

export default Reports;
