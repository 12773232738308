import React from "react";
import { Button, Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

const InventoryExport = ({ inventoryData, user, dateRange }) => {
  const exportToCSV = () => {
    const formattedData = inventoryData.map((item) => ({
      Date: dayjs(item.dateModified).format("YYYY-MM-DD HH:mm:ss"),
      User: item.userName || "Unknown User",
      Action: item.inventoryLog.action,
      Item: item.inventoryLog.itemName,
      Changes: item.inventoryLog.changes,
    }));

    const sheet = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "Inventory History");
    const filename = `${dateRange[0].format(
      "YYYY-MM-DD"
    )}_to_${dateRange[1].format("YYYY-MM-DD")}-${
      user.companyname || "Company"
    }-InventoryReport.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text(`Generated By: ${user.firstname} ${user.lastname}`, 10, 10);
    doc.text(
      `Date Covered: ${dateRange[0].format(
        "YYYY-MM-DD"
      )} to ${dateRange[1].format("YYYY-MM-DD")}`,
      10,
      15
    );

    doc.setFontSize(16);
    doc.text("Inventory History", 10, 25);
    autoTable(doc, {
      margin: { top: 30 },
      head: [["Date", "User", "Action", "Item", "Changes"]],
      body: inventoryData.map((item) => [
        dayjs(item.dateModified).format("YYYY-MM-DD HH:mm:ss"),
        item.userName || "Unknown User",
        item.inventoryLog.action,
        item.inventoryLog.itemName,
        item.inventoryLog.changes,
      ]),
    });

    const filename = `${dateRange[0].format(
      "YYYY-MM-DD"
    )}_to_${dateRange[1].format("YYYY-MM-DD")}-${
      user.companyname || "Company"
    }-InventoryReport.pdf`;
    doc.save(filename);
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        justifyContent: "center",
        marginBottom: 4,
      }}
    >
      <Button
        variant="contained"
        color="success"
        startIcon={<FileDownloadIcon />}
        onClick={exportToCSV}
      >
        Export to CSV
      </Button>
      <Button
        variant="contained"
        color="error"
        startIcon={<PictureAsPdfIcon />}
        onClick={exportToPDF}
      >
        Export to PDF
      </Button>
    </Box>
  );
};

export default InventoryExport;
