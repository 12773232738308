import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Box,
} from "@mui/material";

const modulesList = [
  "SalesReports",
  "InventoryManagement",
  "MenuItemsCreation",
  "Access",
  "Reports",
  "ReceiptConfiguration",
  "Views",
];

const formatModuleName = (name) => name.replace(/([a-z])([A-Z])/g, "$1 $2");

const AddRoleModal = ({ open, onClose, onRoleAdded }) => {
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [selectedModules, setSelectedModules] = useState([]);

  const handleToggleModule = (module) => {
    setSelectedModules((prev) =>
      prev.includes(module)
        ? prev.filter((m) => m !== module)
        : [...prev, module]
    );
  };

  const handleAddRole = async () => {
    const newRole = {
      name: roleName,
      description: roleDescription,
      modules: selectedModules,
    };

    try {
      await onRoleAdded(newRole);
      setRoleName("");
      setRoleDescription("");
      setSelectedModules([]);
      onClose();
    } catch (error) {
      console.error("Error adding role:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add New Role</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Role Name"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            fullWidth
            sx={{ mt: 1 }}
          />
          <TextField
            label="Role Description"
            value={roleDescription}
            onChange={(e) => setRoleDescription(e.target.value)}
            fullWidth
            multiline
            rows={3}
          />
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Assign Modules
          </Typography>
          <FormGroup>
            {modulesList.map((module) => (
              <FormControlLabel
                key={module}
                control={
                  <Checkbox
                    checked={selectedModules.includes(module)}
                    onChange={() => handleToggleModule(module)}
                  />
                }
                label={formatModuleName(module)}
              />
            ))}
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleAddRole}
          variant="contained"
          color="secondary"
          disabled={!roleName || selectedModules.length === 0}
        >
          Add Role
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddRoleModal;
