import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import SecureLS from "secure-ls";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
} from "@mui/material";
import GenericViewHero from "./GenericViewHero";

const socket = io(process.env.REACT_APP_BACKEND_URL, {
  transports: ["websocket", "polling"],
});

const ls = new SecureLS();

const KitchenView = () => {
  const [orders, setOrders] = useState([]);
  const [flashingOrders, setFlashingOrders] = useState({});
  const [orderTimers, setOrderTimers] = useState({});
  const [orderTimestamps, setOrderTimestamps] = useState({});

  useEffect(() => {
    const savedTimers = ls.get("orderTimers");
    const savedTimestamps = ls.get("orderTimestamps");

    if (savedTimers && savedTimestamps) {
      const currentTime = Date.now();

      const recalculatedTimers = Object.fromEntries(
        Object.entries(savedTimers).map(([orderNumber, time]) => {
          const elapsedTime = Math.floor(
            (currentTime - savedTimestamps[orderNumber]) / 1000
          );
          return [orderNumber, Math.max(time - elapsedTime, 0)];
        })
      );

      setOrderTimers(recalculatedTimers);
    }

    const savedOrders = ls.get("orders");
    if (savedOrders) {
      setOrders(savedOrders);
    }

    socket.on("connect", () => {
      console.log("Connected to Socket.IO server:", socket.id);
    });

    socket.on("orderList", (orderList) => {
      console.log("Initial orders received:", orderList);
      setOrders(orderList);
      ls.set("orders", orderList);

      socket.emit("orderList", orderList);
    });

    socket.on("newOrder", (newOrder) => {
      const bell = new Audio("/assets/sounds/orderarrive.mp3");
      bell.play();

      setOrders((prevOrders) => {
        const updatedOrders = [...prevOrders, newOrder];
        ls.set("orders", updatedOrders);

        setOrderTimers((prevTimers) => {
          const updatedTimers = {
            ...prevTimers,
            [newOrder.orderNumber]: newOrder.processTime * 60,
          };
          ls.set("orderTimers", updatedTimers);

          setOrderTimestamps((prevTimestamps) => {
            const updatedTimestamps = {
              ...prevTimestamps,
              [newOrder.orderNumber]: Date.now(),
            };
            ls.set("orderTimestamps", updatedTimestamps);
            return updatedTimestamps;
          });

          return updatedTimers;
        });

        socket.emit("newOrder", newOrder);
        return updatedOrders;
      });
    });

    socket.on("cancelOrder", ({ orderNumber }) => {
      console.log("Received cancelOrder: ", orderNumber);

      setOrders((prevOrders) => {
        console.log("Previous orders before cancellation:", prevOrders);
        const updatedOrders = prevOrders.filter(
          (order) => order.orderNumber !== orderNumber
        );
        console.log("Updated orders after filtering:", updatedOrders);
        return updatedOrders;
      });

      setOrderTimers((prevTimers) => {
        const { [orderNumber]: _, ...remainingTimers } = prevTimers;
        return remainingTimers;
      });

      setFlashingOrders((prevFlashing) => {
        const { [orderNumber]: _, ...remainingFlashing } = prevFlashing;
        return remainingFlashing;
      });

      console.log(
        "Order removed from KitchenView for orderNumber:",
        orderNumber
      );
    });

    return () => {
      socket.off("connect");
      socket.off("orderList");
      socket.off("newOrder");
      socket.off("cancelOrder");
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setOrderTimers((prevTimers) => {
        const updatedTimers = {};

        for (const [orderNumber, time] of Object.entries(prevTimers)) {
          if (
            orders.some((order) => order.orderNumber.toString() === orderNumber)
          ) {
            if (time === 61) {
              const buzzer = new Audio("/assets/sounds/orderalmostelapse.mp3");
              buzzer.play();

              setFlashingOrders((prevFlashing) => ({
                ...prevFlashing,
                [orderNumber]: true,
              }));
            }
            updatedTimers[orderNumber] = Math.max(time - 1, 0);
          }
        }

        ls.set("orderTimers", updatedTimers);
        return updatedTimers;
      });

      setOrderTimestamps((prevTimestamps) => {
        const updatedTimestamps = {
          ...prevTimestamps,
          ...Object.fromEntries(
            orders.map((order) => [
              order.orderNumber,
              prevTimestamps[order.orderNumber] || Date.now(),
            ])
          ),
        };
        ls.set("orderTimestamps", updatedTimestamps);
        return updatedTimestamps;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [orders]);

  const handleProcessOrder = (index) => {
    const updatedOrders = [...orders];
    updatedOrders[index].status = "Processing";
    setOrders(updatedOrders);
    ls.set("orders", updatedOrders);

    socket.emit("updateOrderStatus", {
      status: "Processing",
      order: updatedOrders[index],
    });
  };

  const handleOrderComplete = (index) => {
    const completedOrder = orders[index];
    const updatedOrders = [...orders];
    updatedOrders.splice(index, 1);
    setOrders(updatedOrders);
    ls.set("orders", updatedOrders);

    socket.emit("updateOrderStatus", {
      status: "Completed",
      order: completedOrder,
    });
  };

  const handleCancelOrder = (index) => {
    const cancelledOrder = orders[index];
    const updatedOrders = [...orders];
    updatedOrders.splice(index, 1);
    setOrders(updatedOrders);
    ls.set("orders", updatedOrders);

    socket.emit("updateOrderStatus", {
      status: "Cancelled",
      order: cancelledOrder,
    });

    socket.emit("cancelOrder", {
      status: "Cancelled",
      order: cancelledOrder,
    });

    console.log("Cancelled order emitted:", cancelledOrder);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <GenericViewHero title="Kitchen View" />
      {orders.length === 0 ? (
        <Typography variant="h6" align="center" sx={{ mt: 5 }}>
          No Orders
        </Typography>
      ) : (
        <Grid container spacing={3}>
          {orders.map((order, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                sx={{
                  border: flashingOrders[order.orderNumber]
                    ? "2px solid red"
                    : "1px solid gray",
                  backgroundColor: flashingOrders[order.orderNumber]
                    ? "#ffe6e6"
                    : "white",
                  transition: "background-color 0.3s, border 0.3s",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    Order #: {order.orderNumber}
                    <span
                      style={{
                        color:
                          orderTimers[order.orderNumber] === 0 ||
                          orderTimers[order.orderNumber] === undefined
                            ? "red"
                            : "inherit",
                        fontWeight:
                          orderTimers[order.orderNumber] === 0 ||
                          orderTimers[order.orderNumber] === undefined
                            ? "bold"
                            : "normal",
                      }}
                    >
                      {orderTimers[order.orderNumber] === 0 ||
                      orderTimers[order.orderNumber] === undefined
                        ? "Order Behind"
                        : `${Math.floor(
                            orderTimers[order.orderNumber] / 60
                          )}:${(orderTimers[order.orderNumber] % 60)
                            .toString()
                            .padStart(2, "0")}`}
                    </span>
                  </Typography>
                  <Typography variant="body1">
                    Items:
                    <ul>
                      {order.items.map((item, i) => (
                        <li key={i}>
                          {item.name} - {item.amount}x
                        </li>
                      ))}
                    </ul>
                  </Typography>
                  <Typography variant="body1">
                    Total: PHP {order.total.toFixed(2)}
                  </Typography>
                  <Typography variant="body1">
                    Sales Type: {order.salesType}
                  </Typography>
                  <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                    {/* <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleCancelOrder(index)}
                    >
                      Cancel
                    </Button> */}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleProcessOrder(index)}
                      disabled={
                        order.status === "Processing" ||
                        order.status === "Completed"
                      }
                    >
                      Process Order
                    </Button>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => handleOrderComplete(index)}
                      disabled={order.status !== "Processing"}
                    >
                      Order Complete
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default KitchenView;
