import React from "react";
import { Grid, Box, Typography, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Restaurant, PointOfSale, ShoppingBag } from "@mui/icons-material";
import ViewsHero from "./ViewsHero";

const ViewCard = ({ title, onClick, icon: Icon }) => {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        width: "100%",
        padding: 4,
        textAlign: "center",
        color: "#fff",
        backgroundColor: "#3f51b5",
        "&:hover": {
          backgroundColor: "#303f9f",
        },
        height: "100%",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Icon sx={{ fontSize: 60, marginBottom: 2 }} />
        <Typography variant="h6">{title}</Typography>
      </Paper>
    </Button>
  );
};

const Views = () => {
  const navigate = useNavigate();

  const viewModules = [
    { title: "Kitchen View", path: "/views/kitchen", icon: Restaurant },
    { title: "Front of Desk View", path: "/views/cashier", icon: PointOfSale },
    { title: "Claiming View", path: "/views/claiming", icon: ShoppingBag },
  ];

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <ViewsHero />
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        {viewModules.map((view) => (
          <Grid
            key={view.title}
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ display: "flex" }}
          >
            <ViewCard
              title={view.title}
              icon={view.icon}
              onClick={() => navigate(view.path)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Views;
