import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Tooltip,
  Pagination,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import axios from "axios";
import ReportHero from "./ReportHero";
import ActionModal from "./Modal/ActionModal";
import { getAuthCredentials } from "../../utils/auth";
import generateZReadingPDF from "./Format/ZReading";
import generateXReadingPDF from "./Format/XReading";

const ReportRecords = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [records, setRecords] = useState([]);
  const [actionModalOpen, setActionModalOpen] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchDate, setSearchDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [receiptConfig, setReceiptConfig] = useState(null);
  const { token } = getAuthCredentials();

  const fetchRecords = async (date = "", tab = activeTab, page = 1) => {
    if (!date) {
      return;
    }
    try {
      setLoading(true);

      setRecords([]);
      setFilteredRecords([]);

      const endpoint =
        tab === 0
          ? `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/receipts`
          : tab === 1
          ? `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/xreadings`
          : `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/zreadings`;

      const response = await axios.get(endpoint, {
        params: { startDate: date, page, limit: 10 },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (tab === 0) {
        setRecords(response.data.receipts || []);
        setFilteredRecords(response.data.receipts || []);
      } else if (tab === 1) {
        setRecords(response.data.xReadings || []);
        setFilteredRecords(response.data.xReadings || []);
      } else {
        setRecords(response.data.zReadings || []);
        setFilteredRecords(response.data.zReadings || []);
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    fetchRecords(searchDate, newValue);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchRecords(searchDate, activeTab, newPage);
  };

  const handleActionModalOpen = (type, receipt) => {
    setSelectedReceipt(receipt);
    setActionModalOpen(true);
  };

  const handleActionModalClose = () => {
    setActionModalOpen(false);
    setSelectedReceipt(null);
  };

  const handleDateSearch = (e) => {
    const value = e.target.value;
    setSearchDate(value);
    setRecords([]);
    setFilteredRecords([]);
    fetchRecords(value);
  };

  const handleViewClick = (record) => {
    if (!receiptConfig) {
      console.error("Receipt configuration not found.");
      return;
    }

    if (!record) {
      console.error("Record data not found.");
      return;
    }

    try {
      const generatePDF =
        activeTab === 1 ? generateXReadingPDF : generateZReadingPDF;
      const pdfUrl = generatePDF(receiptConfig, record);
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";
      link.click();
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  useEffect(() => {
    if (!searchDate) {
      const currentDate = new Date().toISOString().split("T")[0];
      setSearchDate(currentDate);
      fetchRecords(currentDate, activeTab);
    }
  }, []);

  useEffect(() => {
    fetchRecords(searchDate, activeTab, currentPage);
  }, [activeTab, currentPage]);

  useEffect(() => {
    const fetchReceiptConfig = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/receipt/config`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setReceiptConfig(response.data.receiptConfig || null);
      } catch (error) {
        console.error("Error fetching receipt configuration:", error);
        setReceiptConfig(null);
      }
    };

    fetchReceiptConfig();
  }, [token]);

  useEffect(() => {
    if (activeTab === 0) {
      setTotalPages(Math.ceil(records.length / 10));
    }
  }, [records]);

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <ReportHero />
      <Typography variant="h5" textAlign="center" gutterBottom>
        {activeTab === 0
          ? "Receipt Records"
          : activeTab === 1
          ? "X-Reading Records"
          : "Z-Reading Records"}
      </Typography>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        sx={{ marginBottom: 3 }}
      >
        <Tab label="Receipts" />
        <Tab label="X-Reading" />
        <Tab label="Z-Reading" />
      </Tabs>
      <Box sx={{ marginBottom: 3, display: "flex", justifyContent: "center" }}>
        <TextField
          label="Search by Date"
          type="date"
          value={searchDate}
          onChange={handleDateSearch}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          sx={{ maxWidth: 400 }}
        />
      </Box>
      {/* Records Table */}
      <Paper elevation={3} sx={{ padding: 2 }}>
        {loading ? (
          <Typography variant="body1" textAlign="center">
            Loading records...
          </Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                {activeTab === 0 && <TableCell>Receipt No.</TableCell>}
                <TableCell>{activeTab === 0 ? "Order" : "Date"}</TableCell>
                {activeTab === 1 && <TableCell>Shift</TableCell>}
                <TableCell>Total Sales</TableCell>
                {activeTab === 0 && <TableCell>Status</TableCell>}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRecords.map((record) => (
                <TableRow key={record.receiptId}>
                  {activeTab === 0 && (
                    <>
                      {/* Hidden field for receiptNumber */}
                      <TableCell style={{ display: "none" }}>
                        {record._id || "N/A"}
                      </TableCell>

                      {/* Visible field (optional) */}
                      <TableCell>{record.receiptNumber || "N/A"}</TableCell>
                    </>
                  )}
                  <TableCell>
                    {activeTab === 0
                      ? record.items.map((item) => item.name).join(", ")
                      : new Date(
                          record.startDate || record.createdAt
                        ).toLocaleDateString()}{" "}
                  </TableCell>

                  {activeTab === 1 && (
                    <TableCell>
                      {record.startDate && record.endDate
                        ? `${new Date(record.startDate).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })} - ${new Date(record.endDate).toLocaleTimeString(
                            [],
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}`
                        : "N/A"}
                    </TableCell>
                  )}
                  <TableCell>
                    {record.grossSales?.toFixed(2) ||
                      record.totalSales?.toFixed(2) ||
                      record.total?.toFixed(2) ||
                      "N/A"}
                  </TableCell>
                  {activeTab === 0 && (
                    <TableCell>{record.status || "N/A"}</TableCell>
                  )}
                  <TableCell>
                    <Tooltip title="View">
                      <IconButton
                        onClick={() =>
                          activeTab === 0
                            ? handleActionModalOpen("View", record)
                            : handleViewClick(record)
                        }
                      >
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {totalPages > 1 && (
              <Box
                sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}
              >
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(event, value) => handlePageChange(value)}
                />
              </Box>
            )}
          </Table>
        )}
        {filteredRecords.length === 0 && !loading && (
          <Typography
            variant="body1"
            textAlign="center"
            color="textSecondary"
            sx={{ marginTop: 2 }}
          >
            No records found.
          </Typography>
        )}
      </Paper>
      <ActionModal
        fetchRecords={fetchRecords}
        open={actionModalOpen}
        handleClose={handleActionModalClose}
        receipt={selectedReceipt}
        onActionComplete={() => fetchRecords(searchDate, activeTab)}
      />
    </Box>
  );
};

export default ReportRecords;
