import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import SecureLS from "secure-ls";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";

const ls = new SecureLS({ encodingType: "aes" });

const EditInventoryInstanceDialog = ({
  open,
  onClose,
  inventoryId,
  instance,
  instanceIndex,
  fetchInventory,
}) => {
  const [deliveryDate, setDeliveryDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [purchaseAmount, setPurchaseAmount] = useState("");
  const [currentAmount, setCurrentAmount] = useState("");
  const [inventoryPrice, setInventoryPrice] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (instance) {
      setDeliveryDate(
        instance.deliveryDate
          ? dayjs(instance.deliveryDate).format("YYYY-MM-DD")
          : ""
      );
      setExpiryDate(
        instance.expiryDate
          ? dayjs(instance.expiryDate).format("YYYY-MM-DD")
          : ""
      );
      setPurchaseAmount(instance.purchaseAmount || "");
      setCurrentAmount(instance.currentAmount || "");
      setInventoryPrice(instance.inventoryPrice || "");
      setStatus(instance.status || "");
    }
  }, [instance]);

  const handleEditInstance = async () => {
    const user = ls.get("user");
    const token = ls.get("authToken");

    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Unauthorized",
        text: "You must be logged in to edit an instance.",
      });
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/edit-instance/${inventoryId}/${instanceIndex}`,
        {
          deliveryDate,
          expiryDate,
          purchaseAmount,
          currentAmount,
          inventoryPrice,
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Inventory instance updated successfully.",
      });

      fetchInventory(); // Refresh the inventory data
      onClose();
    } catch (error) {
      console.error("Error updating instance:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update instance. Please try again.",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Edit Inventory Instance</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          label="Delivery Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={deliveryDate}
          onChange={(e) => setDeliveryDate(e.target.value)}
        />
        <TextField
          margin="normal"
          label="Expiry Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={expiryDate}
          onChange={(e) => setExpiryDate(e.target.value)}
          inputProps={{
            min: deliveryDate || new Date().toISOString().split("T")[0], // Expiry date must not be before delivery date
          }}
        />
        <TextField
          margin="normal"
          label="Purchased Items Amount"
          type="number"
          fullWidth
          value={purchaseAmount}
          onChange={(e) => setPurchaseAmount(e.target.value)}
        />
        <TextField
          margin="normal"
          label="Current Stock Amount"
          type="number"
          fullWidth
          value={currentAmount}
          onChange={(e) => setCurrentAmount(e.target.value)}
        />
        <TextField
          margin="normal"
          label="Purchase Price"
          type="number"
          fullWidth
          value={inventoryPrice}
          onChange={(e) => setInventoryPrice(e.target.value)}
        />
        <TextField
          margin="normal"
          label="Status"
          fullWidth
          select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          SelectProps={{
            native: true,
          }}
        >
          <option value="">Select Status</option>
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleEditInstance} color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditInventoryInstanceDialog;
