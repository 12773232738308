import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
} from "@mui/material";
import SecureLS from "secure-ls";
import Swal from "sweetalert2";
import GenericViewHero from "./GenericViewHero";

const socket = io(process.env.REACT_APP_BACKEND_URL, {
  transports: ["websocket", "polling"],
});

const frontDeskLS = new SecureLS();
const userLS = new SecureLS({ encodingType: "aes" });

const FrontOfDeskView = () => {
  const [allOrders, setAllOrders] = useState({
    newOrders: [],
    processingOrders: [],
    completedOrders: [],
    claimedOrders: [],
    historicalOrders: [],
  });

  useEffect(() => {
    const savedOrders = frontDeskLS.get("frontDeskOrders") || {};

    if (savedOrders) {
      setAllOrders({
        newOrders: savedOrders.newOrders || [],
        processingOrders: savedOrders.processingOrders || [],
        completedOrders: savedOrders.completedOrders || [],
        claimedOrders: savedOrders.claimedOrders || [],
        historicalOrders: savedOrders.historicalOrders || [],
      });
    }

    socket.on("orderList", (orderList) => {
      console.log("Initial orders received at Front-Of-Desk:", orderList);
      setAllOrders((prev) => {
        const updated = {
          ...prev,
          newOrders: orderList,
        };
        frontDeskLS.set("frontDeskOrders", updated);
        return updated;
      });
    });

    socket.on("newOrder", (newOrder) => {
      console.log("New order received at Front-Of-Desk:", newOrder);
      setAllOrders((prev) => {
        const updated = {
          ...prev,
          newOrders: [...prev.newOrders, newOrder],
        };
        frontDeskLS.set("frontDeskOrders", updated);
        return updated;
      });
    });

    socket.on("updateOrderStatus", ({ status, order }) => {
      console.log("updateOrderStatus event received:", { status, order });
      setAllOrders((prev) => {
        const {
          newOrders,
          processingOrders,
          completedOrders,
          claimedOrders,
          historicalOrders,
        } = prev;

        let updatedNew = [...newOrders];
        let updatedProc = [...processingOrders];
        let updatedComp = [...completedOrders];
        let updatedClaim = [...claimedOrders];

        if (status === "Processing") {
          const existing = updatedNew.find(
            (item) => item.orderNumber === order.orderNumber
          );
          if (existing) {
            updatedNew = updatedNew.filter(
              (item) => item.orderNumber !== order.orderNumber
            );
            updatedProc.push(existing);
          } else {
            updatedProc.push(order);
          }
        } else if (status === "Completed") {
          const existing = updatedProc.find(
            (item) => item.orderNumber === order.orderNumber
          );
          if (existing) {
            updatedProc = updatedProc.filter(
              (item) => item.orderNumber !== order.orderNumber
            );
            updatedComp.push(existing);
          } else {
            updatedComp.push(order);
          }
        } else if (status === "Claimed") {
          const existing = updatedComp.find(
            (item) => item.orderNumber === order.orderNumber
          );
          if (existing) {
            updatedComp = updatedComp.filter(
              (item) => item.orderNumber !== order.orderNumber
            );
            updatedClaim.push(existing);
          } else {
            updatedClaim.push(order);
          }
        }

        const updatedState = {
          newOrders: updatedNew,
          processingOrders: updatedProc,
          completedOrders: updatedComp,
          claimedOrders: updatedClaim,
          historicalOrders: [...historicalOrders],
        };
        frontDeskLS.set("frontDeskOrders", updatedState);
        return updatedState;
      });
    });

    socket.on("updateHistoricalOrders", ({ order, action }) => {
      setAllOrders((prev) => {
        const historicalOrderExists = prev.historicalOrders.some(
          (historicalOrder) => {
            const existingId = String(historicalOrder.orderNumber);
            const newId = String(order.orderNumber);
            console.log(
              `Checking existing orderNumber: ${existingId} against new orderNumber: ${newId}`
            );
            return existingId === newId;
          }
        );

        const updatedHistorical = historicalOrderExists
          ? prev.historicalOrders
          : [...prev.historicalOrders, { ...order, action }];

        const updatedState = {
          ...prev,
          historicalOrders: updatedHistorical,
        };

        frontDeskLS.set("frontDeskOrders", updatedState);
        return updatedState;
      });
    });

    return () => {
      socket.off("orderList");
      socket.off("newOrder");
      socket.off("updateOrderStatus");
      socket.off("updateHistoricalOrders");
    };
  }, []);

  const handleCancelOrder = (receiptId, fromProcessing = false) => {
    const user = userLS.get("user");
    if (!user) {
      console.error("User data not found in SecureLS.");
      return;
    }

    console.log("newOrders:", allOrders.newOrders);

    setAllOrders((prev) => {
      let {
        newOrders,
        processingOrders,
        completedOrders,
        claimedOrders,
        historicalOrders,
      } = prev;

      let cancelledOrder;

      cancelledOrder = [
        ...newOrders,
        ...processingOrders,
        ...completedOrders,
        ...claimedOrders,
      ].find((o) => o.receiptId === receiptId);

      if (!cancelledOrder) {
        console.log(
          `Order with Receipt ID ${receiptId} not found in any orders.`
        );
        return prev;
      }

      if (newOrders.find((o) => o.receiptId === receiptId)) {
        newOrders = newOrders.filter((o) => o.receiptId !== receiptId);
      } else if (processingOrders.find((o) => o.receiptId === receiptId)) {
        processingOrders = processingOrders.filter(
          (o) => o.receiptId !== receiptId
        );
      } else if (completedOrders.find((o) => o.receiptId === receiptId)) {
        completedOrders = completedOrders.filter(
          (o) => o.receiptId !== receiptId
        );
      } else if (claimedOrders.find((o) => o.receiptId === receiptId)) {
        claimedOrders = claimedOrders.filter((o) => o.receiptId !== receiptId);
      }

      const updatedCancelledOrder = {
        ...cancelledOrder,
        salesType: "Cancelled",
      };
      const historicalOrderExists = historicalOrders.some(
        (order) => order.receiptId === receiptId
      );

      const updatedHistorical = historicalOrderExists
        ? historicalOrders
        : [...historicalOrders, updatedCancelledOrder];

      const updatedState = {
        newOrders,
        processingOrders,
        completedOrders,
        claimedOrders,
        historicalOrders: updatedHistorical,
      };

      frontDeskLS.set("frontDeskOrders", updatedState);

      socket.emit("updateOrderStatus", {
        status: "Cancelled",
        order: cancelledOrder,
      });

      socket.emit("cancelOrder", {
        orderNumber: cancelledOrder.orderNumber,
      });

      socket.emit("cancelOrderUnique", {
        receiptId,
        user: {
          posId: user.posId,
          userId: user.userId,
          firstname: user.firstname,
          lastname: user.lastname,
        },
      });

      console.log(`Cancelled order emitted for receiptId: ${receiptId}`);
      return updatedState;
    });
  };

  const handleClaimOrder = (index) => {
    setAllOrders((prev) => {
      const newCompleted = [...prev.completedOrders];
      const claimedOrder = newCompleted[index];
      if (!claimedOrder) return prev;

      newCompleted.splice(index, 1);
      const newClaimed = [...prev.claimedOrders, claimedOrder];

      socket.emit("updateOrderStatus", {
        status: "Claimed",
        order: claimedOrder,
      });

      const updated = {
        ...prev,
        completedOrders: newCompleted,
        claimedOrders: newClaimed,
      };
      frontDeskLS.set("frontDeskOrders", updated);
      return updated;
    });
  };

  const handleReturnOrder = (receiptId) => {
    const user = userLS.get("user");
    if (!user) {
      console.error("User data not found in SecureLS.");
      return;
    }

    setAllOrders((prev) => {
      let { claimedOrders, historicalOrders } = prev;

      const returnedOrder = claimedOrders.find(
        (order) => order.receiptId === receiptId
      );

      if (!returnedOrder) {
        console.log(
          `Order with Receipt ID ${receiptId} not found in claimedOrders.`
        );
        return prev;
      }

      const updatedReturnedOrder = {
        ...returnedOrder,
        salesType: "Returned",
      };

      const updatedHistorical = historicalOrders.some(
        (order) => order.receiptId === receiptId
      )
        ? historicalOrders.map((order) =>
            order.receiptId === receiptId ? updatedReturnedOrder : order
          )
        : [...historicalOrders, updatedReturnedOrder];

      const updatedState = {
        ...prev,
        claimedOrders: claimedOrders.filter(
          (order) => order.receiptId !== receiptId
        ),
        historicalOrders: updatedHistorical,
      };

      frontDeskLS.set("frontDeskOrders", updatedState);

      socket.emit("returnOrderUnique", {
        receiptId,
        user: {
          posId: user.posId,
          userId: user.userId,
          firstname: user.firstname,
          lastname: user.lastname,
        },
      });

      console.log(`Returned order emitted for receiptId: ${receiptId}`);
      return updatedState;
    });
  };

  const handleRefundOrder = (receiptId) => {
    const user = userLS.get("user");
    if (!user) {
      console.error("User data not found in SecureLS.");
      return;
    }

    setAllOrders((prev) => {
      let { claimedOrders, historicalOrders } = prev;

      const refundedOrder = claimedOrders.find(
        (order) => order.receiptId === receiptId
      );

      if (!refundedOrder) {
        console.log(
          `Order with Receipt ID ${receiptId} not found in claimedOrders.`
        );
        return prev;
      }

      const updatedRefundedOrder = {
        ...refundedOrder,
        salesType: "Refunded",
      };

      const updatedHistorical = historicalOrders.some(
        (order) => order.receiptId === receiptId
      )
        ? historicalOrders.map((order) =>
            order.receiptId === receiptId ? updatedRefundedOrder : order
          )
        : [...historicalOrders, updatedRefundedOrder];

      const updatedState = {
        ...prev,
        claimedOrders: claimedOrders.filter(
          (order) => order.receiptId !== receiptId
        ),
        historicalOrders: updatedHistorical,
      };

      frontDeskLS.set("frontDeskOrders", updatedState);

      socket.emit("refundOrderUnique", {
        receiptId,
        user: {
          posId: user.posId,
          userId: user.userId,
          firstname: user.firstname,
          lastname: user.lastname,
        },
      });

      console.log(`Refunded order emitted for receiptId: ${receiptId}`);
      return updatedState;
    });
  };

  const handleCompleteOrder = (index) => {
    setAllOrders((prev) => {
      const newProc = [...prev.processingOrders];
      const completedOrder = newProc[index];
      if (!completedOrder) return prev;

      newProc.splice(index, 1);
      const newComp = [...prev.completedOrders, completedOrder];

      const updated = {
        ...prev,
        processingOrders: newProc,
        completedOrders: newComp,
      };
      frontDeskLS.set("frontDeskOrders", updated);
      return updated;
    });
  };

  const { newOrders, processingOrders, completedOrders, claimedOrders } =
    allOrders;

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <GenericViewHero title="Front Of Desk View" />

      {/* Orders */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mt: 3, textAlign: "center" }}>
            New Orders
          </Typography>
          <Grid container spacing={3}>
            {newOrders.map((order, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">
                      Order #: {order.orderNumber}
                    </Typography>
                    <Typography variant="body1">
                      Items:
                      <ul>
                        {order.items.map((item, i) => (
                          <li key={i}>
                            {item.name} - {item.amount}x
                          </li>
                        ))}
                      </ul>
                    </Typography>
                    <Typography variant="body1">
                      Total: PHP {order.total.toFixed(2)}
                    </Typography>
                    <Typography variant="body1">
                      Sales Type: {order.salesType}
                    </Typography>
                    <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleCancelOrder(order.receiptId, true)}
                      >
                        Cancel Order
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>

      {/* Orders Being Processed */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mt: 3, textAlign: "center" }}>
            Orders Being Processed
          </Typography>
          <Grid container spacing={3}>
            {processingOrders.map((order, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">
                      Order #: {order.orderNumber}
                    </Typography>
                    <Typography variant="body1">
                      Items:
                      <ul>
                        {order.items.map((item, i) => (
                          <li key={i}>
                            {item.name} - {item.amount}x
                          </li>
                        ))}
                      </ul>
                    </Typography>
                    <Typography variant="body1">
                      Total: PHP {order.total.toFixed(2)}
                    </Typography>
                    <Typography variant="body1">
                      Sales Type: {order.salesType}
                    </Typography>
                    <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleCancelOrder(order.receiptId, true)}
                      >
                        Cancel Order
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>

      {/* Completed Orders */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mt: 3, textAlign: "center" }}>
            Completed Orders
          </Typography>
          <Grid container spacing={3}>
            {completedOrders.map((order, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">
                      Order #: {order.orderNumber}
                    </Typography>
                    <Typography variant="body1">
                      Items:
                      <ul>
                        {order.items.map((item, i) => (
                          <li key={i}>
                            {item.name} - {item.amount}x
                          </li>
                        ))}
                      </ul>
                    </Typography>
                    <Typography variant="body1">
                      Total: PHP {order.total.toFixed(2)}
                    </Typography>
                    <Typography variant="body1">
                      Sales Type: {order.salesType}
                    </Typography>
                    <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleClaimOrder(index)}
                      >
                        Order Claimed
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>

      {/* Claimed Orders */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mt: 3, textAlign: "center" }}>
            Claimed Orders
          </Typography>
          <Grid container spacing={3}>
            {[...claimedOrders].reverse().map((order, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">
                      Order #: {order.orderNumber}
                    </Typography>
                    <Typography variant="body1">
                      Items:
                      <ul>
                        {order.items.map((item, i) => (
                          <li key={i}>
                            {item.name} - {item.amount}x
                          </li>
                        ))}
                      </ul>
                    </Typography>
                    <Typography variant="body1">
                      Total: PHP {order.total.toFixed(2)}
                    </Typography>
                    <Typography variant="body1">
                      Sales Type: {order.salesType}
                    </Typography>
                    <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => handleReturnOrder(order.receiptId)}
                      >
                        Return Order
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleRefundOrder(order.receiptId)}
                      >
                        Refund Order
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>

      {/* Historical Orders */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mt: 3, textAlign: "center" }}>
            Historical Orders
          </Typography>
          <Box sx={{ textAlign: "center", mb: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "This will clear all historical orders!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, clear it!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setAllOrders((prev) => {
                      const updatedState = { ...prev, historicalOrders: [] };
                      frontDeskLS.set("frontDeskOrders", updatedState);
                      return updatedState;
                    });
                    Swal.fire(
                      "Cleared!",
                      "Historical orders have been cleared.",
                      "success"
                    );
                  }
                });
              }}
            >
              Clear Historical Orders
            </Button>
          </Box>
          <Grid container spacing={3}>
            {(allOrders.historicalOrders || []).map((order, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">
                      Order #: {order.orderNumber}
                    </Typography>
                    <Typography variant="body1">
                      Items:
                      <ul>
                        {order.items.map((item, i) => (
                          <li key={i}>
                            {item.name} - {item.amount}x
                          </li>
                        ))}
                      </ul>
                    </Typography>
                    <Typography variant="body1">
                      Total: PHP {order.total.toFixed(2)}
                    </Typography>
                    <Typography variant="body1">
                      Sales Type: {order.salesType}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FrontOfDeskView;
