import React, { useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import axios from "axios";
import { getAuthCredentials } from "../../../utils/auth";

const ActionModal = ({
  fetchRecords,
  open,
  handleClose,
  actionType,
  receipt,
  onActionComplete,
}) => {
  const [selectedAction, setSelectedAction] = useState("");
  const { token } = getAuthCredentials();
  console.log(receipt);

  const handleAction = async () => {
    const endpoint =
      selectedAction === "Cancel"
        ? "/v1/api/order/cancel-order"
        : selectedAction === "Return"
        ? "/v1/api/order/return-order"
        : "/v1/api/order/refund-order";

    const payload = {
      receiptId: receipt?._id || null, 
      receiptNumber: receipt?.receiptNumber || null, 
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      onActionComplete();
      handleClose();
      fetchRecords();
    } catch (error) {
      console.error("Error processing action:", error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" textAlign="center">
          Receipt Actions
        </Typography>
        <Box mt={2} display="flex" justifyContent="space-around">
          <Button
            variant="contained"
            color="error"
            onClick={() => setSelectedAction("Cancel")}
            disabled={
              !receipt ||
              receipt.status === "cancelled" ||
              receipt.status === "returned" ||
              receipt.status === "refunded"
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => setSelectedAction("Return")}
            disabled={
              !receipt ||
              receipt.status === "returned" ||
              receipt.status === "refunded" ||
              receipt.status === "cancelled"
            }
          >
            Return
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => setSelectedAction("Refund")}
            disabled={
              !receipt ||
              receipt.status === "refunded" ||
              receipt.status === "cancelled"
            }
          >
            Refund
          </Button>
        </Box>
        {selectedAction && (
          <Box mt={3} textAlign="center">
            <Typography>
              Are you sure you want to {selectedAction.toLowerCase()} this
              receipt?
            </Typography>
            <Box mt={2} display="flex" justifyContent="space-around">
              <Button variant="outlined" onClick={handleAction}>
                Confirm
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setSelectedAction("")}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ActionModal;
