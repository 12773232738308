import React from "react";
import { Button, Box } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";

const SalesExport = ({
  transactionLogs,
  revenueExpenseData,
  incomeBarData,
  expenseBarData,
  user,
  dateRange,
}) => {
  const exportToCSV = () => {
    const calculateColumnWidths = (sheet) => {
      const colWidths = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      for (let col = range.s.c; col <= range.e.c; col++) {
        let maxWidth = 10;
        for (let row = range.s.r; row <= range.e.r; row++) {
          const cell = sheet[XLSX.utils.encode_cell({ r: row, c: col })];
          if (cell && cell.v) {
            const cellValue = String(cell.v);
            maxWidth = Math.max(maxWidth, cellValue.length);
          }
        }
        colWidths.push({ wch: maxWidth + 2 });
      }
      return colWidths;
    };

    const transactionTotal = transactionLogs.reduce(
      (acc, log) => acc + (log.amount || 0),
      0
    );
    const transactionData = [
      ...transactionLogs,
      { id: "TOTAL", amount: transactionTotal },
    ];

    const summaryData = [
      {},
      { id: "Generated By:", amount: `${user.firstname} ${user.lastname}` },
      { id: "Company:", amount: user.companyname || "N/A" },
      { id: "POS ID:", amount: user.posId || "N/A" },
      {
        id: "Date Covered:",
        amount: `${dateRange[0].format("YYYY-MM-DD")} to ${dateRange[1].format(
          "YYYY-MM-DD"
        )}`,
      },
    ];

    const combinedData = [...transactionData, ...summaryData];

    const transactionSheet = combinedData?.length
      ? XLSX.utils.json_to_sheet(combinedData)
      : XLSX.utils.json_to_sheet([
          { message: "No transaction logs available." },
        ]);

    const chartData = revenueExpenseData?.labels?.length
      ? revenueExpenseData.labels.map((label, index) => ({
          date: label,
          revenue: revenueExpenseData.datasets[0].data[index],
          expense: revenueExpenseData.datasets[1].data[index],
        }))
      : [{ message: "No chart data available." }];
    const chartTotalRevenue = chartData.reduce(
      (acc, data) => acc + (data.revenue || 0),
      0
    );
    const chartTotalExpense = chartData.reduce(
      (acc, data) => acc + (data.expense || 0),
      0
    );
    chartData.push({
      date: "TOTAL",
      revenue: chartTotalRevenue,
      expense: chartTotalExpense,
    });
    const chartSheet = XLSX.utils.json_to_sheet(chartData);

    const incomeData = incomeBarData?.labels?.length
      ? incomeBarData.labels.map((label, index) => ({
          category: label,
          income: incomeBarData.datasets[0].data[index],
        }))
      : [{ message: "No income data available." }];
    const totalIncome = incomeData.reduce(
      (acc, data) => acc + (data.income || 0),
      0
    );
    incomeData.push({ category: "TOTAL", income: totalIncome });
    const incomeSheet = XLSX.utils.json_to_sheet(incomeData);

    const expenseData = expenseBarData?.labels?.length
      ? expenseBarData.labels.map((label, index) => ({
          category: label,
          expense: expenseBarData.datasets[0].data[index],
        }))
      : [{ message: "No expense data available." }];
    const totalExpense = expenseData.reduce(
      (acc, data) => acc + (data.expense || 0),
      0
    );
    expenseData.push({ category: "TOTAL", expense: totalExpense });
    const expenseSheet = XLSX.utils.json_to_sheet(expenseData);

    transactionSheet["!cols"] = calculateColumnWidths(transactionSheet);
    chartSheet["!cols"] = calculateColumnWidths(chartSheet);
    incomeSheet["!cols"] = calculateColumnWidths(incomeSheet);
    expenseSheet["!cols"] = calculateColumnWidths(expenseSheet);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, transactionSheet, "Transaction Logs");
    XLSX.utils.book_append_sheet(wb, chartSheet, "Chart Data");
    XLSX.utils.book_append_sheet(wb, incomeSheet, "Income by Category");
    XLSX.utils.book_append_sheet(wb, expenseSheet, "Expenses by Category");

    const filename = `${dateRange[0].format(
      "YYYY-MM-DD"
    )}_to_${dateRange[1].format("YYYY-MM-DD")}-${
      user.companyname || "Company"
    }-SalesReport.xlsx`;

    XLSX.writeFile(wb, filename);
  };

  const exportToPDF = () => {
    console.log("user: ", user);
    const doc = new jsPDF();

    const currentDate = new Date().toLocaleString();
    const firstname = user.firstname || "Unknown";
    const lastname = user.lastname || "Unknown";
    const posId = user.posId || "Unknown";
    const companyName = user.companyname || "Your Company Name";

    doc.setFontSize(10);
    doc.text(`Generated By: ${firstname} ${lastname}`, 10, 10);
    doc.text(`Company: ${companyName}`, 10, 15);
    doc.text(`POS ID: ${posId}`, 10, 20);
    doc.text(`Generated On: ${currentDate}`, 10, 25);
    doc.text(
      `Date Covered: ${dateRange[0].format(
        "YYYY-MM-DD"
      )} to ${dateRange[1].format("YYYY-MM-DD")}`,
      10,
      30
    );

    doc.setFontSize(16);
    doc.text("Revenue and Expense Details", 10, 40);
    autoTable(doc, {
      startY: 45,
      head: [["Date", "Revenue", "Expense"]],
      body: [
        ...revenueExpenseData.labels.map((label, index) => [
          label,
          revenueExpenseData.datasets[0].data[index],
          revenueExpenseData.datasets[1].data[index],
        ]),
        [
          "TOTAL",
          revenueExpenseData.datasets[0].data.reduce((a, b) => a + b, 0),
          revenueExpenseData.datasets[1].data.reduce((a, b) => a + b, 0),
        ],
      ],
    });

    doc.addPage();
    doc.text("Income by Category", 10, 10);
    autoTable(doc, {
      head: [["Category", "Income"]],
      body: [
        ...incomeBarData.labels.map((label, index) => [
          label,
          incomeBarData.datasets[0].data[index],
        ]),
        ["TOTAL", incomeBarData.datasets[0].data.reduce((a, b) => a + b, 0)],
      ],
    });

    doc.addPage();
    doc.text("Expenses by Category", 10, 10);
    autoTable(doc, {
      head: [["Category", "Expense"]],
      body: [
        ...expenseBarData.labels.map((label, index) => [
          label,
          expenseBarData.datasets[0].data[index],
        ]),
        ["TOTAL", expenseBarData.datasets[0].data.reduce((a, b) => a + b, 0)],
      ],
    });

    doc.addPage();
    doc.text("Transaction Logs", 10, 10);
    autoTable(doc, {
      head: [["ID", "Date", "Item", "Amount", "Type"]],
      body: transactionLogs.map((log) => [
        log.id,
        log.date,
        log.item,
        log.amount,
        log.type,
      ]),
    });

    const filename = `${dateRange[0].format(
      "YYYY-MM-DD"
    )}_to_${dateRange[1].format("YYYY-MM-DD")}-${companyName}-SalesReport.pdf`;

    doc.save(filename);
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        justifyContent: "center",
        marginBottom: 4,
      }}
    >
      <Button
        variant="contained"
        color="success"
        startIcon={<FileDownloadIcon />}
        onClick={exportToCSV}
      >
        Export to CSV
      </Button>
      <Button
        variant="contained"
        color="error"
        startIcon={<PictureAsPdfIcon />}
        onClick={exportToPDF}
      >
        Export to PDF
      </Button>
    </Box>
  );
};

export default SalesExport;
