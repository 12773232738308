import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";

// Main Pages
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import Dashboard from "../pages/dashboard/Dashboard";
import Sales from "../pages/sales/Sales";
import Inventory from "../pages/Inventory/Inventory";
import InventoryArchive from "../pages/Inventory/InventoryArchive";
import InventoryHistory from "../pages/Inventory/InventoryHistory";
import MenuCreation from "../pages/menucreation/MenuCreation";
import MenuArchive from "../pages/menucreation/MenuArchive";
import AccountManagement from "../pages/account/AccountManagement";
import AccessManagement from "../pages/access/AccessManagement";
import Reports from "../pages/reports/Reports";
import ReportRecords from "../pages/reports/ReportRecords";
import SystemLogs from "../pages/reports/SystemLogs";
import ReceiptConfig from "../pages/receipt/ReceiptConfig";
import ReceiptTest from "../pages/receipt/ReceiptTest";
import Views from "../pages/views/Views";
import KitchenView from "../pages/views/KitchenView";
import FrontOfDeskView from "../pages/views/FrontOfDeskView";
import NotFound from "../pages/NotFound";

const LiveRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/404" element={<NotFound />} />

      {/* Protected Routes */}
      <Route element={<ProtectedRoutes />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/sales" element={<Sales />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/inventory/archive" element={<InventoryArchive />} />
        <Route path="/inventory/history" element={<InventoryHistory />} />
        <Route path="/menucreation" element={<MenuCreation />} />
        <Route path="/menucreation/archived" element={<MenuArchive />} />
        <Route path="/account-management" element={<AccountManagement />} />
        <Route path="/access-management" element={<AccessManagement />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/reports/records" element={<ReportRecords />} />
        <Route path="/reports/system-logs" element={<SystemLogs />} />
        <Route path="/receipt-config" element={<ReceiptConfig />} />
        <Route path="/receipt-print" element={<ReceiptTest />} />
        <Route path="/views" element={<Views />} />
        <Route path="/views/kitchen" element={<KitchenView />} />
        <Route path="/views/cashier" element={<FrontOfDeskView />} />
        <Route path="/views/claiming" element={<div>Claiming View</div>} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Route>
    </Routes>
  );
};

export default LiveRoutes;
