import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { getAuthCredentials } from "../../../utils/auth";

const EJournalModal = ({ open, onClose }) => {
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const { token } = getAuthCredentials();

  const handleGenerateReport = async () => {
    if (!date) {
      alert("Please select a date.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/reports/e-journal`,
        { date },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "text/plain",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `E-Journal_${date}.txt`;
        link.click();
        alert("E-Journal generated successfully.");
      }
    } catch (error) {
      console.error("Error generating E-Journal Report:", error);
      alert("Failed to generate the E-Journal. Please try again.");
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Generate E-Journal</DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          Please select the date for the E-Journal Report:
        </Typography>
        <TextField
          label="Date"
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleGenerateReport}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Generating..." : "Generate"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EJournalModal;
