import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import axios from "axios";
import { getAuthCredentials } from "../../../utils/auth";

const SystemTrailModal = ({ open, onClose }) => {
  const [logDate, setLogDate] = useState("");
  const { token } = getAuthCredentials();

  const handleLogReportSubmit = async () => {
    if (!logDate) {
      alert("Please select a date.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/reports/log-report`,
        { date: logDate },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `log_report_${logDate}.txt`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      alert("Log report generated successfully.");
    } catch (error) {
      console.error("Error generating log report:", error);
      alert("Error generating log report.");
    }

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Generate System Log Report</DialogTitle>
      <DialogContent>
        <TextField
          label="Select Date"
          type="date"
          value={logDate}
          onChange={(e) => setLogDate(e.target.value)}
          fullWidth
          sx={{ marginTop: 1 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleLogReportSubmit}
          variant="contained"
          color="primary"
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SystemTrailModal;
