import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate, useLocation } from "react-router-dom";
import SecureLS from "secure-ls";
import { jwtDecode } from "jwt-decode";
import NotFound from "../pages/NotFound";

const ls = new SecureLS({ encodingType: "aes" });

const modulePathMapping = {
  SalesReports: "/sales",
  InventoryManagement: "/inventory",
  MenuItemsCreation: "/menucreation",
  Access: "/access-management",
  AccountManagement: "/account-management",
  Reports: "/reports",
  ReceiptConfiguration: ["/receipt-config", "/receipt-print"], 
  Views: ["/views", "/views/kitchen", "/views/cashier"],
};

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authorized, setAuthorized] = useState(true);

  useEffect(() => {
    const user = ls.get("user");
    const authToken = ls.get("authToken");

    if (user && authToken) {
      try {
        const decodedToken = jwtDecode(authToken);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp < currentTime) {
          ls.remove("user");
          ls.remove("authToken");
          navigate("/login", { replace: true });
        } else {
          const userModules = user.modules || [];
          const pathname = location.pathname;

          if (
            user.role === "Superadmin" || 
            pathname === "/account-management" ||
            pathname === "/dashboard" ||
            userModules.some((module) => {
              const paths = modulePathMapping[module];
              return Array.isArray(paths)
                ? paths.includes(pathname) 
                : paths === pathname
            })
          ) {
            setAuthorized(true);
          } else {
            setAuthorized(false); 
          }
        }
      } catch (error) {
        ls.remove("user");
        ls.remove("authToken");
        navigate("/login", { replace: true });
      }
    } else {
      navigate("/login", { replace: true });
    }
  }, [navigate, location]);

  if (!authorized) {
    return <NotFound />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
