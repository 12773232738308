import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
} from "@mui/material";

const LegendDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Inventory Legend</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: "#FFCCCC", 
              borderRadius: "4px",
            }}
          />
          <Typography>Red: Expired Stock</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: "#FFFF99", 
              borderRadius: "4px",
            }}
          />
          <Typography>Yellow: 3 Days Before Expiring</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: "#90EE90",
              borderRadius: "4px",
            }}
          />
          <Typography>Green: More Than 4 Days to Expire</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LegendDialog;
