import React, { useState } from "react";
import Swal from "sweetalert2";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  InputLabel,
  FormControl,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import SecureLS from "secure-ls";
import AddCategoryDialog from "./AddCategoryDialog";

const ls = new SecureLS({ encodingType: "aes" });

const AddInventoryDialog = ({
  open,
  onClose,
  onAdd,
  categories,
  fetchCategories,
  setCategories,
}) => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [amountOrdered, setAmountOrdered] = useState("");
  const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
  const [price, setPrice] = useState("");
  const [priceType, setPriceType] = useState("Total");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [isAddingCategory, setIsAddingCategory] = useState(false);

  const handleDeleteCategory = async (categoryId) => {
    const user = ls.get("user");
    const token = ls.get("authToken");
    const posId = user?.posId;

    if (!token || !posId) {
      onClose();
      return;
    }

    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/delete-category`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { categoryId, posId },
        }
      );

      await fetchCategories(token);

      alert(`Category has been successfully deleted.`);
    } catch (error) {
      console.error("Error deleting category:", error);
      alert("Failed to delete category. Please try again.");
    }
  };

  const handleAdd = () => {
    let finalPrice = price;

    if (priceType === "Per Piece" && amountOrdered) {
      finalPrice = price * amountOrdered;
    }

    const newItem = {
      name,
      categoryId: category,
      amountOrdered,
      price: finalPrice,
      deliveryDate,
      expiryDate,
      unitOfMeasurement,
    };

    onAdd(newItem);

    Swal.fire({
      title: "Added!",
      text: `${name} has been successfully added to the inventory.`,
      icon: "success",
      confirmButtonText: "OK",
    });
    resetFields();
    onClose();
  };

  const resetFields = () => {
    setName("");
    setCategory("");
    setAmountOrdered("");
    setUnitOfMeasurement("");
    setPrice("");
    setDeliveryDate("");
    setExpiryDate("");
  };

  const isFormValid =
    name &&
    category &&
    amountOrdered &&
    unitOfMeasurement &&
    price &&
    deliveryDate &&
    expiryDate;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add Inventory</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            value={category || ""}
            onChange={(e) => setCategory(e.target.value)}
            displayEmpty
            renderValue={(selected) => {
              const selectedCategory = categories.find(
                (cat) => cat.categoryId === selected
              );
              return selectedCategory ? selectedCategory.categoryName : "";
            }}
          >
            {categories.map((cat) => (
              <MenuItem
                key={cat.categoryId}
                value={cat.categoryId}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                {cat.categoryName}
                <Tooltip title={"Delete category"}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(cat.categoryId);
                    }}
                    color="error"
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </MenuItem>
            ))}
            <MenuItem onClick={() => setIsAddingCategory(true)}>
              Add New Category
            </MenuItem>
          </Select>
        </FormControl>

        {isAddingCategory && (
          <AddCategoryDialog
            open={isAddingCategory}
            onClose={() => setIsAddingCategory(false)}
            onCategoryAdded={(newCategory) => {
              setCategories((prevCategories) => [
                ...prevCategories,
                newCategory,
              ]);
              setIsAddingCategory(false);
            }}
          />
        )}

        <TextField
          margin="normal"
          label="Items Ordered"
          type="number"
          fullWidth
          value={amountOrdered}
          onChange={(e) => setAmountOrdered(e.target.value)}
        />

        <TextField
          margin="normal"
          label="Unit of Measurement"
          fullWidth
          value={unitOfMeasurement}
          onChange={(e) => setUnitOfMeasurement(e.target.value)}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="price-type-label">Price Type</InputLabel>
          <Select
            labelId="price-type-label"
            value={priceType}
            onChange={(e) => setPriceType(e.target.value)}
            displayEmpty
          >
            <MenuItem value="Total">Total</MenuItem>
            <MenuItem value="Per Piece">Per Piece</MenuItem>
          </Select>
        </FormControl>

        <TextField
          margin="normal"
          label={priceType === "Per Piece" ? "Purchase Price per Unit" : "Total Purchase Price"}
          type="number"
          fullWidth
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />

        <TextField
          margin="normal"
          label="Delivery Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={deliveryDate}
          onChange={(e) => setDeliveryDate(e.target.value)}
        />

        <TextField
          margin="normal"
          label="Expiry Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={expiryDate}
          onChange={(e) => setExpiryDate(e.target.value)}
          inputProps={{
            min: deliveryDate || new Date().toISOString().split("T")[0], 
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetFields();
            onClose();
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary" disabled={!isFormValid}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddInventoryDialog;
