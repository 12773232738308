import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

const AlertDialog = ({ open, onClose, expiredItems, lowStockItems }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Inventory Alerts</DialogTitle>
      <DialogContent>
        {expiredItems.length > 0 && (
          <>
            <Typography variant="h6" gutterBottom>
              Expired Items
            </Typography>
            <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Item Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Expiry Date</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {expiredItems.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.inventoryName}</TableCell>
                      <TableCell>
                        {new Date(item.expiryDate).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {lowStockItems.length > 0 && (
          <>
            <Typography variant="h6" gutterBottom>
              Low Stock Items
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Item Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Stock Level</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lowStockItems.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.inventoryName}</TableCell>
                      <TableCell>
                        {item.currentAmount === 0 ? (
                          <Typography sx={{ color: "red", fontWeight: "bold" }}>
                            0 - No Stock
                          </Typography>
                        ) : (
                          item.currentAmount
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {expiredItems.length === 0 && lowStockItems.length === 0 && (
          <Typography>No alerts to display.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
