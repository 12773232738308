import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { getAuthCredentials } from "../../../utils/auth";

const HandleArchiveItem = ({ item, onClose, onArchiveConfirm }) => {
  const { token } = getAuthCredentials();

  const handleConfirmArchive = async () => {
    try {
      const { inventoryId, instanceIndex, isMainInventory } = item;

      if (!inventoryId) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Missing inventory ID. Cannot archive the item.",
        });
        return;
      }

      if (isMainInventory) {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/archive`,
          { inventoryId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Main Inventory Item ${item.inventoryName} archived successfully.`,
        });
      } else {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/v1/api/inventory/archive-instance`,
          { inventoryId, instanceIndex },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `Instance from ${item.inventoryName} archived successfully.`,
        });
      }

      onArchiveConfirm();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Failed to archive ${item.inventoryName}. Please try again.`,
      });
      console.error("Error archiving item:", error);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>
        Archive {item.isMainInventory ? "Main Inventory Item" : "Instance"}
      </DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to archive{" "}
          {item.isMainInventory ? (
            <strong>the main inventory item</strong>
          ) : (
            <strong>this instance</strong>
          )}{" "}
          of <strong>{item.inventoryName}</strong>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleConfirmArchive} color="primary">
          Archive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HandleArchiveItem;
