import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  Box,
  Typography,
} from "@mui/material";

const EditRoleModal = ({ open, onClose, role, onRoleUpdated }) => {
  const [formData, setFormData] = useState({
    name: "",
    modules: [],
  });

  const allModules = [
    "SalesReports",
    "InventoryManagement",
    "MenuItemsCreation",
    "Access",
    "Reports",
    "ReceiptConfiguration",
    "Views",
  ];

  useEffect(() => {
    if (role) {
      setFormData({
        name: role.name || "",
        modules: role.modules || [],
      });
    }
  }, [role]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleModuleChange = (module) => {
    setFormData((prev) => {
      const updatedModules = prev.modules.includes(module)
        ? prev.modules.filter((m) => m !== module)
        : [...prev.modules, module];
      return { ...prev, modules: updatedModules };
    });
  };

  const handleSubmit = async () => {
    if (!formData.name) {
      alert("Role name is required.");
      return;
    }

    console.log("formData: ", formData);

    await onRoleUpdated(role._id, formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Role</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Role Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            required
            sx={{ marginTop: 1 }}
          />
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Modules
          </Typography>
          <FormGroup>
            {allModules.map((module) => (
              <FormControlLabel
                key={module}
                control={
                  <Checkbox
                    checked={formData.modules.includes(module)}
                    onChange={() => handleModuleChange(module)}
                  />
                }
                label={module.replace(/([A-Z])/g, " $1").trim()}
              />
            ))}
          </FormGroup>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRoleModal;
