import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

const RestockDialog = ({ open, onClose, onRestock }) => {
  const [restockData, setRestockData] = useState({
    amountOrdered: "",
    price: "",
    deliveryDate: "",
    expiryDate: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRestockData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Restock Inventory</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Items Ordered"
          name="amountOrdered"
          type="number"
          margin="dense"
          value={restockData.amountOrdered}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          label="Price"
          name="price"
          type="number"
          margin="dense"
          value={restockData.price}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          label="Delivery Date"
          name="deliveryDate"
          type="date"
          margin="dense"
          InputLabelProps={{ shrink: true }}
          value={restockData.deliveryDate}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          label="Expiry Date"
          name="expiryDate"
          type="date"
          margin="dense"
          InputLabelProps={{ shrink: true }}
          value={restockData.expiryDate}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => onRestock(restockData)}
          color="primary"
          disabled={
            !restockData.amountOrdered ||
            !restockData.price ||
            !restockData.deliveryDate ||
            !restockData.expiryDate
          }
        >
          Restock
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestockDialog;
