import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const AccessHero = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ padding: 3 }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/dashboard")}
        sx={{ marginBottom: 3 }}
      >
        Back
      </Button>
      <Typography variant="h4" align="center" gutterBottom>
        Access Management
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: 4,
          gap: 2,
        }}
      >
        {/* <Button
          variant="outlined"
          onClick={() => navigate("/access-management")}
        >
          Manage Access
        </Button> */}
      </Box>
    </Box>
  );
};

export default AccessHero;
