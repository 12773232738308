import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  TableContainer,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import SalesInventory from "./SalesInventory";
import SalesExport from "./SalesExport";
import { getAuthCredentials } from "../../utils/auth";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Sales = () => {
  const navigate = useNavigate();
  const { user, token, posId } = getAuthCredentials(navigate);
  if (!user || !token || !posId) {
    return null;
  }

  const [activeTab, setActiveTab] = useState(0);
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(7, "day"),
    dayjs(),
  ]);

  const [salesData, setSalesData] = useState([]);
  const [revenueExpenseData, setRevenueExpenseData] = useState(null);
  const [incomeBarData, setIncomeBarData] = useState(null);
  const [expenseBarData, setExpenseBarData] = useState(null);
  const [transactionLogs, setTransactionLogs] = useState([]);
  const [totals, setTotals] = useState({ totalRevenue: 0, totalExpense: 0 });

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filteredTransactionLogs, setFilteredTransactionLogs] = useState([]);
  const [typeFilter, setTypeFilter] = useState("All");

  const handleDateRangeChange = (index, newValue) => {
    const newRange = [...dateRange];
    newRange[index] = newValue;
    setDateRange(newRange);
  };

  const fetchSalesData = async () => {
    try {
      const startDate = dateRange[0].format("YYYY-MM-DD");
      const endDate = dateRange[1].format("YYYY-MM-DD");

      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/v1/api/sales/reports`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            startDate,
            endDate,
            category: "Sales",
            posId: user.posId,
          },
        }
      );

      setSalesData(data);

      // Process data for charts and logs
      processRevenueExpenseData(data);
      processIncomeBarData(data);
      processExpenseBarData(data);
      processTransactionLogs(data);
    } catch (error) {
      console.error("Error fetching sales data:", error);
    }
  };

  const processRevenueExpenseData = (data) => {
    const dateMap = {};

    data.forEach((entry) => {
      const date = dayjs(entry.date).format("YYYY-MM-DD");
      if (!dateMap[date]) {
        dateMap[date] = { revenue: 0, expense: 0 };
      }

      entry.entries.forEach((item) => {
        const amount = item.price;
        if (item.subcategory === "Income") {
          dateMap[date].revenue += amount;
        } else if (item.subcategory === "Expense") {
          dateMap[date].expense += amount;
        }
      });
    });

    const labels = Object.keys(dateMap).sort();
    const revenues = labels.map((date) => dateMap[date].revenue);
    const expenses = labels.map((date) => dateMap[date].expense);

    setRevenueExpenseData({
      labels,
      datasets: [
        {
          label: "Revenue",
          data: revenues,
          backgroundColor: "green",
        },
        {
          label: "Expense",
          data: expenses,
          backgroundColor: "red",
        },
      ],
    });
  };

  const processIncomeBarData = (data) => {
    const nameMap = {};

    data.forEach((entry) => {
      entry.entries.forEach((item) => {
        if (item.subcategory === "Income") {
          const name = item.name || "Unknown";
          const amount = item.price;

          if (!nameMap[name]) {
            nameMap[name] = 0;
          }
          nameMap[name] += amount;
        }
      });
    });

    const sortedEntries = Object.entries(nameMap).sort(([, a], [, b]) => a - b);

    const labels = sortedEntries.map(([name]) => name);
    const amounts = sortedEntries.map(([, value]) => value);

    setIncomeBarData({
      labels,
      datasets: [
        {
          label: "Income",
          data: amounts,
          backgroundColor: [
            "#36A2EB",
            "#FFCE56",
            "#FF6384",
            "#4BC0C0",
            "#9966FF",
          ],
        },
      ],
    });
  };

  const processExpenseBarData = (data) => {
    const nameMap = {};

    data.forEach((entry) => {
      entry.entries.forEach((item) => {
        if (item.subcategory === "Expense") {
          // Group "Refunded" entries under "Refunds"
          const name = item.name.startsWith("Refunded")
            ? "Refunds"
            : item.name || "Unknown";
          const amount = item.price;

          if (!nameMap[name]) {
            nameMap[name] = 0;
          }
          nameMap[name] += amount;
        }
      });
    });

    const sortedEntries = Object.entries(nameMap).sort(([, a], [, b]) => a - b);

    const labels = sortedEntries.map(([name]) => name);
    const amounts = sortedEntries.map(([, value]) => value);

    setExpenseBarData({
      labels,
      datasets: [
        {
          label: "Expenses",
          data: amounts,
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
          ],
        },
      ],
    });
  };

  const processTransactionLogs = (data) => {
    const logs = [];
    let totalRevenue = 0;
    let totalExpense = 0;

    data.forEach((entry) => {
      entry.entries.forEach((item) => {
        if (
          item.category === "Inventory" &&
          (item.subcategory === "Deduction" || item.subcategory === "Addition")
        ) {
          return;
        }

        const amount = item.price;
        const formattedAmount =
          item.subcategory === "Expense" ? -amount : amount;

        logs.push({
          id: entry._id,
          date:
            item.name?.startsWith("Refunded") && entry.updatedAt
              ? dayjs(entry.updatedAt).format("YYYY-MM-DD HH:mm:ss")
              : dayjs(entry.createdAt).format("YYYY-MM-DD HH:mm:ss"),
          item: item.name,
          amount: formattedAmount,
          type: `${item.category} - ${item.subcategory}`,
        });

        if (item.subcategory === "Income") {
          totalRevenue += amount;
        } else if (item.subcategory === "Expense") {
          totalExpense += amount;
        }
      });
    });

    logs.sort((a, b) => new Date(b.date) - new Date(a.date));

    setTransactionLogs(logs);
    setTotals({ totalRevenue, totalExpense });
  };

  const netAmount = totals.totalRevenue - totals.totalExpense;

  useEffect(() => {
    fetchSalesData();
  }, [dateRange]);

  const totalPages = Math.ceil(transactionLogs.length / limit);

  useEffect(() => {
    const filteredLogs = transactionLogs.filter((log) =>
      typeFilter === "All" ? true : log.type === typeFilter
    );

    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    setFilteredTransactionLogs(filteredLogs.slice(startIndex, endIndex));
  }, [page, limit, transactionLogs, typeFilter]);

  return (
    <Box sx={{ padding: 3 }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        sx={{ marginBottom: 3 }}
      >
        Back
      </Button>
      <Typography variant="h4" align="center" gutterBottom>
        Reports
      </Typography>

      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        centered
        sx={{ marginBottom: 3 }}
      >
        <Tab label="Sales Reports" />
        <Tab label="Inventory Reports" />
      </Tabs>

      {activeTab === 0 ? (
        <>
          <Box
            sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={dateRange[0]}
                onChange={(newValue) => handleDateRangeChange(0, newValue)}
                maxDate={dayjs()}
                sx={{ marginRight: 2 }}
              />
              <DatePicker
                label="End Date"
                value={dateRange[1]}
                onChange={(newValue) => handleDateRangeChange(1, newValue)}
                maxDate={dayjs()}
              />
            </LocalizationProvider>
          </Box>

          <SalesExport
            transactionLogs={transactionLogs}
            revenueExpenseData={revenueExpenseData}
            incomeBarData={incomeBarData}
            expenseBarData={expenseBarData}
            user={user}
            dateRange={dateRange}
          />

          {/* Revenue and Expense Bar Chart */}
          <Typography variant="h5" align="center" gutterBottom>
            Revenue and Expense Over Time
          </Typography>
          {revenueExpenseData && (
            <Box
              sx={{
                maxWidth: 800,
                width: "90%",
                margin: "0 auto",
              }}
            >
              <Bar data={revenueExpenseData} options={{ responsive: true }} />
            </Box>
          )}

          {/* Sales Bar Chart */}
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ marginTop: 6 }}
          >
            Sales by Category (Income)
          </Typography>
          {incomeBarData && (
            <Box
              sx={{
                maxWidth: 800,
                width: "90%",
                margin: "0 auto",
              }}
            >
              <Bar data={incomeBarData} options={{ responsive: true }} />
            </Box>
          )}

          {/* Expense Bar Chart */}
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ marginTop: 6 }}
          >
            Expenses by Category
          </Typography>
          {expenseBarData && (
            <Box
              sx={{
                maxWidth: 800,
                width: "90%",
                margin: "0 auto",
              }}
            >
              <Bar data={expenseBarData} options={{ responsive: true }} />
            </Box>
          )}

          {/* Transaction Logs */}
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ marginTop: 6 }}
          >
            Transaction Logs
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 2,
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography variant="subtitle1">Type Filter:</Typography>
              <select
                value={typeFilter}
                onChange={(e) => {
                  setTypeFilter(e.target.value);
                  setPage(1);
                }}
                style={{
                  padding: "5px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "16px",
                }}
              >
                <option value="All">All</option>
                {[...new Set(transactionLogs.map((log) => log.type))].map(
                  (type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  )
                )}
              </select>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Typography variant="subtitle1">Logs per page:</Typography>
              <select
                value={limit}
                onChange={(e) => {
                  setLimit(parseInt(e.target.value));
                  setPage(1);
                }}
                style={{
                  padding: "5px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  fontSize: "16px",
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
            </Box>
          </Box>
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table aria-label="transaction logs table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTransactionLogs.map((log, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    <TableCell>{log.id}</TableCell>
                    <TableCell>{log.date}</TableCell>
                    <TableCell>{log.item}</TableCell>
                    <TableCell>
                      {log.amount < 0 ? "-" : ""}
                      {Math.abs(log.amount).toFixed(2)}
                    </TableCell>
                    <TableCell>{log.type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, value) => setPage(value)}
              color="primary"
            />
          </Box>

          {/* Totals Display */}
          <Box sx={{ marginTop: 3, textAlign: "left" }}>
            <Typography variant="h6">
              Total Revenue: {totals.totalRevenue.toFixed(2)}
            </Typography>
            <Typography variant="h6">
              Total Expense: -{totals.totalExpense.toFixed(2)}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: netAmount >= 0 ? "green" : "red",
                fontWeight: "bold",
              }}
            >
              Net Amount: {netAmount >= 0 ? "" : "-"}
              {Math.abs(netAmount).toFixed(2)}
            </Typography>
          </Box>
        </>
      ) : (
        // Inventory Reports Tab Content
        <SalesInventory dateRange={dateRange} />
      )}
    </Box>
  );
};

export default Sales;
